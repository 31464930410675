import { SimpleGrid } from '@mantine/core'
import { useSocialMediaResults } from '@/api/results'
import { SocialResult } from '@/components/lib/results/Result'
import {
  usePostSortPreference,
  useSetPostSortPreference,
} from '@/stores/queries-store'
import { useTimelineFilteredResults } from '@/stores/results-filter-store'
import { ROUTES } from '@/utils/constants.ts'
import {
  isSocialMediaResult,
  type SocialMediaResult,
} from '@/utils/types/result-types.ts'
import { orderBy } from 'lodash'
import { PanelBase } from './'

interface SocialPanelProps {
  isFullscreen?: boolean
  position?: 'left' | 'center' | 'right'
}

export function SocialPanel({
  isFullscreen = false,
  position,
}: SocialPanelProps) {
  const unfilteredSocialResults = useSocialMediaResults()

  const filteredSocialResults: SocialMediaResult[] =
    useTimelineFilteredResults().categorized.socialMediaResults
  const socialResults = isFullscreen
    ? filteredSocialResults
    : unfilteredSocialResults

  const shouldDisplay = socialResults.length > 0 || isFullscreen

  const sortPreference = usePostSortPreference()
  const setSortPreference = useSetPostSortPreference()

  // Directly order the results just before rendering
  const sortedSocialResults: SocialMediaResult[] = orderBy(
    socialResults,
    sortPreference || 'authoredOn',
    'desc',
  )

  return (
    shouldDisplay && (
      <PanelBase
        titleShort="Social"
        titleLong="All Social Data"
        isFullscreen={isFullscreen}
        isolateType={isSocialMediaResult}
        link={ROUTES.social}
        position={position}
        sortPreference={sortPreference}
        setSortPreference={setSortPreference}
      >
        <SimpleGrid
          cols={isFullscreen ? { xs: 1, sm: 3, md: 4, lg: 5, xl: 6 } : 3}
          spacing={isFullscreen ? 'xl' : 'lg'}
          verticalSpacing={isFullscreen ? 'md' : 70}
        >
          {sortedSocialResults
            .slice(0, isFullscreen ? undefined : 3)
            .map((result) => (
              <SocialResult
                key={result.documentId}
                result={result}
                isFullscreen
              />
            ))}
        </SimpleGrid>
      </PanelBase>
    )
  )
}
