import { Group } from '@mantine/core'
import { useImageById, useImagesByDate } from '@/api/results'
import { ResultImage } from '@/features/ResultImage'
import { VIEWS } from '@/features/TimelineView/constants'
import dayjs from 'dayjs'

export const TimelineComponent = ({
  selectedImages,
  selectedDate,
  setSelectedDate,
  selectedView,
}: {
  selectedImages: {
    primary: string | null
    secondary: string | null
  }
  selectedView: string
  selectedDate: string | null
  setSelectedDate: (date: string | null) => void
}) => {
  const imagesByDate = useImagesByDate()
  const primaryImage = useImageById(selectedImages.primary)
  const secondaryImage = useImageById(selectedImages.secondary)

  const primaryImageDate = primaryImage
    ? dayjs(primaryImage.authoredOn).format('YYYY-MM-DD')
    : null
  const secondaryImageDate = secondaryImage
    ? dayjs(secondaryImage.authoredOn).format('YYYY-MM-DD')
    : null

  console.log({ primaryImageDate, secondaryImageDate })
  const getBorderStyle = (date: string) => {
    const hasPrimary = primaryImageDate === date
    const hasSecondary = secondaryImageDate === date
    const borderStyle = {} as React.CSSProperties
    if (selectedView === VIEWS.inspect && hasPrimary) {
      borderStyle.border = '5px solid blue'
    }
    if (selectedView === VIEWS.compare) {
      if (hasPrimary) {
        borderStyle.borderLeft = '5px solid blue'
      }
      if (hasSecondary) {
        borderStyle.borderRight = '5px solid red'
      }
    }
    return borderStyle
  }

  return (
    <div>
      <Group wrap="nowrap" mt="20px">
        {Object.keys(imagesByDate).map((date) => (
          <div
            key={date}
            style={{
              width: '100%',
              padding: '5px',
              height: '100%',
            }}
          >
            <b
              style={{
                textDecoration: selectedDate === date ? 'underline' : 'none',
              }}
            >
              {date}
            </b>
            <ResultImage
              style={{
                width: '100px',
                height: '100px',
                ...getBorderStyle(date),
              }}
              item={imagesByDate[date][0]}
              onClick={() => setSelectedDate(date)}
            />
            {imagesByDate[date].length > 1 &&
              `+${imagesByDate[date].length - 1} more`}
          </div>
        ))}
      </Group>
    </div>
  )
}
