import { extractCompanyName } from '@/stores/results-filter-store/utils'
import { apiUrl } from '@/utils/constants'
import type { OrthoImageryLayer } from '@/utils/types/ortho-imagery-types'
import type { ImageryResult } from '@/utils/types/result-types'
import type { Polygon } from 'geojson'

export const makeGegdOrthoLayer = (r: ImageryResult): OrthoImageryLayer => {
  const featureId = (r.properties.featureId as string) || r.id
  return {
    source: 'GEGD' as const,
    id: featureId,
    tileUrl:
      `${apiUrl}/provider/gegd/wms?SERVICE=WMS&REQUEST=GetMap&VERSION=1.1.1&SRS=EPSG:3857` +
      `&BBOX={bbox-epsg-3857}&WIDTH=256&HEIGHT=256&LAYERS=DigitalGlobe:Imagery` +
      `&FORMAT=image/png&transparent=true&FEATURECOLLECTION=${featureId}`,
    authoredOn: r.authoredOn,
    geometry: r.geometry as Polygon,
    imageResult: r,
    documentId: r.documentId,
    groupKey: featureId,
    score: r.score,
    formattedSource: `${extractCompanyName(r)}`,
  }
}

export const isGegd = (ir: ImageryResult) => ir.source.toLowerCase() === 'gegd'
