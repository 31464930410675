import MapboxDraw from '@mapbox/mapbox-gl-draw'

export const makeMapboxDraw = (map: mapboxgl.Map) => {
  const draw = new MapboxDraw({
    displayControlsDefault: false,
    defaultMode: 'simple_select',
  })

  //@ts-expect-error clickAnywhere is specific to SimpleSelect and isn't typed separately
  MapboxDraw.modes.simple_select.clickAnywhere = () => {
    map.dragPan.enable()
  }

  //@ts-expect-error clickNoTarget is specific to SimpleSelect and isn't typed separately
  MapboxDraw.modes.direct_select.clickNoTarget = () => {
    map.dragPan.enable()
  }

  return draw
}
