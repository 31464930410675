import { executeQuery } from '@/api/results/api'
import type { SearchResult } from '@/api/results/types'
import {
  useCurrentQuery,
  useCurrentQueryFilters,
  useCurrentQueryStart,
} from '@/stores/queries-store'
import { useQuery } from '@tanstack/react-query'

export const useSearchQuery = () => {
  const queryStart = useCurrentQueryStart()
  const params = {
    query: useCurrentQuery(),
    filters: useCurrentQueryFilters(),
  }
  const enabled = params.query !== '' || params.filters.length > 0
  return useQuery({
    queryKey: ['search', params, queryStart],
    queryFn: () => executeQuery(params),
    enabled: !!queryStart && enabled,
  })
}

export const useSearchData = () => useSearchQuery().data as SearchResult
export const useSearchResults = () => useSearchData()?.resultDocuments || []
export const useSearchMetadata = () => useSearchData()?.queryMetadata

export const useSearchStatus = () => useSearchQuery().status || 'idle'

export const useQueryId = () => useSearchData()?.queryId || ''
