export const EVENTS = {
  clearAll: 'clear-all',
  map: {
    drawCreated: 'map.draw-created',
    drawUpdated: 'map.draw-updated',
    load: 'map.load',
    styleLoaded: 'map.style-loaded',
    toggleDisplay: 'map.toggle-display',
    resultsUpdated: 'map.results-updated',
    orthoLayersUpdated: 'map.ortho-layers-updated',
  },
  mapStore: {
    activeLayersUpdated: 'map-store.active-layers-updated',
    activeOrthoLayersUpdated: 'map-store.active-ortho-layers-updated',
    hoveredExtentsUpdated: 'map-store.hovered-extents-updated',
    hoveredLayerIdUpdated: 'map-store.hovered-layer-id-updated',
    mapBboxUpdated: 'map-store.map-bbox-updated',
    pointViewPortUpdated: 'map-store.point-viewport-updated',
  },
  queriesStore: {
    currentQueryFiltersUpdated: 'queries-store.current-query-filters-updated',
    searchMetadataUpdated: 'queries-store.search-metadata-updated',
  },
  resultsStore: {
    resultsUpdated: 'results-store.results-updated',
  },
} as const
