import { useCategorizedResults, useQueryId, useResultIds } from '@/api/results'
import { useTimelineSelectedIds } from '@/stores/results-filter-store'
import { filterResultsById } from '@/stores/results-filter-store/utils'
import type { CategorizedResults } from '@/utils/types/result-types'

type Results = {
  ids: string[]
  categorized: CategorizedResults
  currentQueryId: string
}

export const useTimelineFilteredResults = (): {
  ids: string[]
  categorized: CategorizedResults
  currentQueryId: string
} => {
  const timelineSelectedIds = useTimelineSelectedIds()
  const currentQueryId = useQueryId() || ''
  const ids = useResultIds()
  const categorized = useCategorizedResults()

  const resultsBundle: Results = { ids, categorized, currentQueryId }
  const filtered = filterResultsById(
    (r) => (timelineSelectedIds ? timelineSelectedIds.includes(r.id) : true),
    resultsBundle,
  )
  return filtered
}
