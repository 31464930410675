import { notifications } from '@mantine/notifications'
import { emitter, EVENTS } from '@/events'
import { useClearChat } from '@/stores/chat-store'
import { useResetFilters } from '@/stores/filters-store'
import {
  useClearSearchHereMarker,
  useClearSearchPolygon,
  useSetCurrentSearchPolygon,
  useSetIsDrawing,
  useSetLastSearchPolygon,
  useSetUploadedFeature,
  useStopDrawingPolygon,
} from '@/stores/map-draw-store'
import {
  useClearActiveLayers,
  useClearOrthoLayerGroupIds,
  useSetMapBbox,
} from '@/stores/map-store'
import {
  useClearQuery,
  useSetInsightFeedbackSubmitted,
  useSetSearchMetadata,
} from '@/stores/queries-store'
import { useResetPostfilters } from '@/stores/results-filter-store'

export function useClearAll() {
  const clearSearchPolygon = useClearSearchPolygon()
  const clearSearchHereMarker = useClearSearchHereMarker()
  const stopDrawingPolygon = useStopDrawingPolygon()
  const setIsDrawing = useSetIsDrawing()
  const setLastSearchPolygon = useSetLastSearchPolygon()
  const clearOrthoLayerIds = useClearOrthoLayerGroupIds()
  const setGeoLocation = useSetMapBbox()
  const clearActiveLayers = useClearActiveLayers()
  const clearQuery = useClearQuery()
  const resetPostFilters = useResetPostfilters()
  const setInsightFeedbackSubmitted = useSetInsightFeedbackSubmitted()
  const clearChat = useClearChat()
  const resetFilters = useResetFilters()
  const setCurrentSearchPolygon = useSetCurrentSearchPolygon()
  const setSearchMetadata = useSetSearchMetadata()
  const setUploadedFeature = useSetUploadedFeature()

  // First: closes socket to stop receiving results
  // Clears all search state: results, query, filters, chat, and map
  function clearAll(preservePolygon = false) {
    notifications.clean()
    clearQuery()
    resetFilters()
    clearChat()
    stopDrawingPolygon()
    setIsDrawing(false)
    setGeoLocation(null)
    setSearchMetadata(null)
    setUploadedFeature(null)
    clearOrthoLayerIds()

    if (!preservePolygon) {
      clearSearchPolygon()
      setLastSearchPolygon(null)
      setCurrentSearchPolygon(null)
      clearSearchHereMarker()
    }

    setInsightFeedbackSubmitted(false)
    clearActiveLayers()
    resetPostFilters()
    emitter.emit(EVENTS.clearAll)
  }

  return clearAll
}
