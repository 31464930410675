import { type MutableRefObject, useEffect } from 'react'
import { useSearchMetadata } from '@/api/results'
import { actOnMap, makePolygonBoundingBox } from '@/features/DantiMap/utils'
import type { ResultWithId } from '@/utils/types/result-types'
import * as turf from '@turf/turf'
import type { Feature, Point } from 'geojson'

export const useInitialBehavior = (
  map: MutableRefObject<mapboxgl.Map | null>,
  item?: ResultWithId,
) => {
  const searchMetadata = useSearchMetadata()
  console.log({ searchMetadata })
  useEffect(() => {
    const fitBoundingBox = (bbox: mapboxgl.LngLatBoundsLike) => {
      map.current?.fitBounds(bbox, {})
    }
    const flyToPointCenter = (point: Feature<Point>) => {
      const [c0, c1] = point.geometry.coordinates
      map.current?.flyTo({ center: [c0, c1], zoom: 8 })
    }
    if (item) {
      if (['Polygon', 'MultiPolygon'].includes(item.geometry.type)) {
        const bbox = makePolygonBoundingBox(item)
        fitBoundingBox(bbox as mapboxgl.LngLatBoundsLike)
      }
      if (item.geometry.type === 'Point') {
        const feature = turf.feature(item.geometry)
        flyToPointCenter(feature)
      }
    }
    if (searchMetadata) {
      const feature = turf.feature(searchMetadata.geometry)
      const { type } = feature.geometry
      actOnMap(() => {
        if (['Polygon', 'MultiPolygon'].includes(type)) {
          const bbox = turf.bbox(feature)
          fitBoundingBox(bbox as mapboxgl.LngLatBoundsLike)
        } else if (type === 'Point') {
          flyToPointCenter(feature as Feature<Point>)
        }
      })
    }
  }, [map, item, searchMetadata])
}
