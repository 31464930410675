import { RESULT_CATEGORIES } from '@/utils/constants'

export const SEARCH_CATEGORIES = {
  ATTOM: RESULT_CATEGORIES.ATTOM,
  INSIGHT: RESULT_CATEGORIES.INSIGHT,
  FIRE: RESULT_CATEGORIES.FIRE,
  HAZARD_PROPERTY: RESULT_CATEGORIES.HAZARD_PROPERTY,
  HAZARD_RISK: RESULT_CATEGORIES.HAZARD_RISK,
  REGRID: RESULT_CATEGORIES.REGRID,
  IMAGE: RESULT_CATEGORIES.IMAGE,
  PUBLICATION: RESULT_CATEGORIES.PUBLICATION,
  SHIP: RESULT_CATEGORIES.SHIP,
  SOCIAL_MEDIA: RESULT_CATEGORIES.SOCIAL_MEDIA,
}
