import React from 'react'
import { Checkbox, Stack } from '@mantine/core'
import { randomId, useListState } from '@mantine/hooks'
import type { ProviderSource } from '@/components/filters/providers/use-providers'
import { capitalize } from 'lodash'

export interface ProviderChecklistProps {
  label: string
  selectedProviders: string[]
  providers: ProviderSource[]
  onUpdate: (category: string, providers: ProviderSource[]) => void
}

export const ProviderChecklist = ({
  label,
  selectedProviders,
  providers,
  onUpdate,
}: ProviderChecklistProps) => {
  const [values, handlers] = useListState(
    providers.map((x) => ({
      ...x,
      checked: selectedProviders.includes(x.source),
      key: randomId(),
    })),
  )

  React.useEffect(() => {
    onUpdate(
      label,
      values.filter((x) => x.checked).map(({ checked, key, ...data }) => data),
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values, label])

  const allChecked = values.every((value) => value.checked)
  const indeterminate = values.some((value) => value.checked) && !allChecked

  const items = values.map((value, index) => (
    <Checkbox
      mt="xs"
      ml={33}
      label={value.source
        .split('_')
        .map((v) => capitalize(v))
        .join(' ')}
      key={value.key}
      checked={value.checked}
      onChange={(event) =>
        handlers.setItemProp(index, 'checked', event.currentTarget.checked)
      }
    />
  ))

  return (
    <Stack gap={4} w={150}>
      <Checkbox
        checked={allChecked}
        indeterminate={indeterminate}
        label={label}
        onChange={() =>
          handlers.setState((current) =>
            current.map((value) => ({ ...value, checked: !allChecked })),
          )
        }
      />
      {items}
    </Stack>
  )
}
