import { getSearchRequest, postSearchRequest } from '@/api/danti-requests'
import type { ExecuteQueryParams, SearchResult } from '@/api/results/types'

export async function executeQuery(params: ExecuteQueryParams) {
  const response = await postSearchRequest(`v2/query?size=100`, params)
  return response.data as SearchResult
}

export async function getQueryResultsByCategory(
  queryId: string,
  category: string,
) {
  const response = await getSearchRequest(`v2/results/${queryId}/${category}`)
  return response.data
}

export async function getQueryResultsByQueryId(queryId: string) {
  const response = await getSearchRequest(`v2/results/${queryId}`)
  return response.data
}

export async function getInsightResult(queryId: string) {
  const response = await getSearchRequest(`v2/insight/${queryId}`)
  return response.data
}
