import type { CollectionItem } from '@/api/collections/types'
import { ResultActions } from '@/features/ResultActions'
import { HIDE_ACTIONS_BY_CATEGORY } from '@/features/ResultActions/constants'
import type { ResultWithId } from '@/utils/types/raw-result-types'

export interface CollectionDetailMenuProps {
  item: CollectionItem
}

export const CollectionDetailMenu = ({ item }: CollectionDetailMenuProps) => {
  const { doc } = item
  const result = JSON.parse(doc) as ResultWithId
  const category = result.properties
    .resultCategory as keyof typeof HIDE_ACTIONS_BY_CATEGORY
  const hideActions = HIDE_ACTIONS_BY_CATEGORY[category] || []
  return (
    <ResultActions
      item={item}
      result={result}
      hideActions={hideActions}
      iconClassName="more_horz"
      className=""
    />
  )
}
