import {
  FireResult,
  ImageResult,
  NewsResult,
  ShipResult,
  SocialResult,
} from '@/components/lib/results/Result'
import { FileResult } from '@/components/lib/results/Result/FileResult'
import type { CollectionItem } from '@/api/collections/types'
import { isFileResult } from '@/utils/types/result-type-checkers'
import {
  isFireResult,
  isImageryResult,
  isShipResult,
  isSocialMediaResult,
  type ResultWithId,
} from '@/utils/types/result-types'

export interface CollectionItemGridResultProps {
  item: CollectionItem
}

export function CollectionItemGridResult({
  item,
}: CollectionItemGridResultProps) {
  const { doc } = item
  const result = JSON.parse(doc) as ResultWithId

  if (isImageryResult(result)) {
    return <ImageResult item={item} result={result} isFullscreen />
  }

  if (isSocialMediaResult(result)) {
    return <SocialResult result={result} isFullscreen />
  }

  if (isFireResult(result)) {
    return <FireResult result={result} isFullscreen />
  }

  if (isShipResult(result)) {
    return <ShipResult result={result} isFullscreen />
  }

  if (isFileResult(result)) {
    return <FileResult result={result} item={item} isFullscreen />
  }

  return (
    <NewsResult
      key={item.id}
      item={item}
      result={result}
      isFullscreen
      listView={false}
    />
  )
}
