import { ActionIcon, Group, Title, Tooltip } from '@mantine/core'
import type { Collection } from '@/api/collections/types'
import { Icon } from '@/components/lib/Icon'
import { openUpdateCollectionModal } from '@/components/modals/openers'
import { BackButton } from '@/features/Collections/components'
import { useCollectionDetailHeader } from '@/features/Collections/hooks'
import pluralize from 'pluralize'

function IconButton({
  icon,
  label,
  onClick,
}: {
  icon: React.ReactNode
  label: string
  onClick: () => void
}) {
  return (
    <Tooltip
      label={label}
      withArrow
      position="bottom"
      color="rgba(97, 97, 97, 0.90)"
    >
      <ActionIcon color="#666" onClick={onClick} variant="subtle">
        {icon}
      </ActionIcon>
    </Tooltip>
  )
}

export function CollectionDetailHeader({
  collection,
  totalCount = 0,
}: {
  collection: Collection
  totalCount?: number
}) {
  const { handleShareClick, openSettings, shareLabel } =
    useCollectionDetailHeader({ collection })

  const icons = {
    download: <Icon name="download" />,
    upload: <Icon name="file_upload" />,
    people: <Icon name="people_alt" />,
    settings: <Icon name="settings" />,
  }

  const handleSettingsClick = () => {
    if (collection.refId == '') {
      openUpdateCollectionModal(collection)
    } else {
      openSettings()
    }
  }

  return (
    <Group justify="space-between">
      <Group>
        <BackButton hiddenFrom="md" />
        <Title c="#00000099" size={18}>
          Saved Searches
        </Title>
      </Group>

      <Group gap="xs">
        <Title c="#00000099" size={18}>
          {`${totalCount} ${pluralize('item', totalCount)}`}
        </Title>
        <IconButton
          icon={icons.people}
          label={shareLabel}
          onClick={handleShareClick}
        />
        <IconButton
          icon={icons.settings}
          label="Settings"
          onClick={handleSettingsClick}
        />
      </Group>
    </Group>
  )
}
