import { useSearchMetadata } from '@/api/results'
import { useMapLayers } from '@/features/DantiMap/hooks'
import * as turf from '@turf/turf'
import type { MutableRefObject } from 'react'
import { EXTENT_LAYER_ID, POINT_LAYER_ID, SOURCE_ID } from './constants'

export const useLocationResponseLayer = (
  map: MutableRefObject<mapboxgl.Map | null>,
) => {
  const searchMetadata = useSearchMetadata()
  let layers
  if (searchMetadata) {
    const geojsonFeature = turf.feature(searchMetadata.geometry)
    const { type } = geojsonFeature.geometry
    const isPoint = ['Point', 'MultiPoint'].includes(type)
    const layerId = isPoint ? POINT_LAYER_ID : EXTENT_LAYER_ID

    layers = {
      [layerId]: {
        sourceId: SOURCE_ID,
        type: isPoint ? 'symbol' : 'fill',
        isVisible: true,
        data: geojsonFeature,
        ...(isPoint
          ? { pointIcon: 'Point Data Icon-Query' }
          : { fillColor: '#F2970A', fillOpacity: 0.2, lineColor: '#F2970A' }),
      },
    }
  }
  useMapLayers({
    map: map.current,
    layers: layers || {},
  })
}
