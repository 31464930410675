import type { Results } from '@/api/results/types'
import { RESULT_CATEGORIES } from '@/utils/constants'
import type {
  AttomResult,
  FireResult,
  HazardPropertyResult,
  HazardRiskResult,
  ImageryResult,
  InsightResult,
  PublicationResult,
  RegridResult,
  ShipResult,
  SocialMediaResult,
} from '@/utils/types/result-types'
import { useResults } from './use-results'

export const useAllResults = () => {
  const results = useResults() as Partial<Results>
  return Object.values({
    [RESULT_CATEGORIES.FIRE]: results[RESULT_CATEGORIES.FIRE] as FireResult[],
    [RESULT_CATEGORIES.SHIP]: results[RESULT_CATEGORIES.SHIP] as ShipResult[],
    [RESULT_CATEGORIES.ATTOM]: results[
      RESULT_CATEGORIES.ATTOM
    ] as AttomResult[],
    [RESULT_CATEGORIES.HAZARD_PROPERTY]: results[
      RESULT_CATEGORIES.HAZARD_PROPERTY
    ] as HazardPropertyResult[],
    [RESULT_CATEGORIES.HAZARD_RISK]: results[
      RESULT_CATEGORIES.HAZARD_RISK
    ] as HazardRiskResult[],
    [RESULT_CATEGORIES.REGRID]: results[
      RESULT_CATEGORIES.REGRID
    ] as RegridResult[],
    [RESULT_CATEGORIES.IMAGE]: results[
      RESULT_CATEGORIES.IMAGE
    ] as ImageryResult[],
    [RESULT_CATEGORIES.PUBLICATION]: results[
      RESULT_CATEGORIES.PUBLICATION
    ] as PublicationResult[],
    [RESULT_CATEGORIES.SOCIAL_MEDIA]: results[
      RESULT_CATEGORIES.SOCIAL_MEDIA
    ] as SocialMediaResult[],
    [RESULT_CATEGORIES.INSIGHT]: results[
      RESULT_CATEGORIES.INSIGHT
    ] as InsightResult[],
  }).flat()
}
