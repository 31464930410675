import { useRef } from 'react'
import { ActionIcon, Box, Flex } from '@mantine/core'
import { Icon } from '@/components/lib/Icon'
import { ResultsPanel } from '@/components/lib/results/ResultsPanel'
import { useBackNavigation } from '@/features/AppRouting/hooks'
import {
  useMapboxMap,
  useOrthoLayers,
  usePointData,
} from '@/features/DantiMap/hooks'
import { ICONS, mapboxToken } from '@/utils/constants'
import type { ResultWithId } from '@/utils/types/raw-result-types'
import { classes } from '@/utils/ui-helpers'
import mapboxgl from 'mapbox-gl'
import {
  useBaseLayerSelection,
  useExtentsLayer,
  useFileDragging,
  useHandleMapToggling,
  useInitialBehavior,
  useLocationResponseLayer,
  useMapFlyToOnDrag,
  useSearchDrawing,
  useZoomToNewOrtholayer,
} from './hooks'
import { MapControlPanel } from './MapControlPanel'
import { MapSearchHereButton } from './MapSearchHereButton'

import 'mapbox-gl/dist/mapbox-gl.css'
import '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css'
import styles from '@/features/DantiMap/danti-map.module.css'

mapboxgl.accessToken = mapboxToken

interface MapSearchPanelProps {
  item?: ResultWithId | undefined
}

export const MapSearchPanel = ({ item }: MapSearchPanelProps) => {
  const mapContainer = useRef<HTMLDivElement | null>(null)
  const map = useRef<mapboxgl.Map | null>(null)
  const searchHereDiv = useRef<HTMLDivElement | null>(null)
  const goBack = useBackNavigation()
  const { onClickCallback } = useSearchDrawing(map, searchHereDiv)
  const { getRootProps, getInputProps, open } = useFileDragging()
  const { isResultsExpanded, showPointData, showExtents, toggleResultsPanel } =
    useHandleMapToggling(map)

  useMapboxMap(map, mapContainer, { showMapControls: true })
  useInitialBehavior(map, item)
  useExtentsLayer({ map, showExtents })
  useBaseLayerSelection(map)
  useMapFlyToOnDrag(map)
  useOrthoLayers(map, showExtents)
  useZoomToNewOrtholayer(map)
  usePointData(map, 'Explore', showPointData)
  useLocationResponseLayer(map)

  const expandIcon = isResultsExpanded
    ? ICONS.rightPanelClose
    : ICONS.rightPanelOpen

  return (
    <Box>
      <MapControlPanel map={map} openFileDialog={open} />
      <ActionIcon
        onClick={toggleResultsPanel}
        className={classes(
          styles.mapSearchExpandButton,
          isResultsExpanded ? styles.expanded : '',
        )}
        radius="xs"
      >
        <Icon name={expandIcon} />
      </ActionIcon>

      <Flex className={styles.mapFlexWrapper}>
        <div className={styles.mapFlexContainer}>
          <div {...getRootProps()} className={styles.mapFlexRoot}>
            <ActionIcon onClick={goBack} className={styles.backButton}>
              <Icon name={ICONS.arrowBack} />
            </ActionIcon>
            <input {...getInputProps()} />
            <div
              ref={mapContainer}
              className={classes('map-container', styles.mapContainer)}
            />
          </div>
        </div>

        <Box
          className={classes(
            styles.mapSearchResultsPanelContainer,
            isResultsExpanded ? styles.expanded : '',
          )}
          data-cy="map-search-results-panel-container"
        >
          <ResultsPanel
            isEmbedded
            onlyOrtho
            data-cy="map-search-results-panel"
          />
        </Box>
      </Flex>
      <div className={styles.mapSearchHereButtonContainer}>
        <div ref={searchHereDiv}>
          <MapSearchHereButton onClick={onClickCallback} />
        </div>
      </div>
    </Box>
  )
}
