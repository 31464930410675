import React from 'react'
import { ActionIcon, Button, SimpleGrid, Stack } from '@mantine/core'
import { usePublicationResults } from '@/api/results'
import { Icon } from '@/components/lib/Icon.tsx'
import { NewsResult } from '@/components/lib/results/Result'
import {
  usePostSortPreference,
  useSetPostSortPreference,
} from '@/stores/queries-store'
import { useTimelineFilteredResults } from '@/stores/results-filter-store'
import { ROUTES } from '@/utils/constants.ts'
import { isPublicationResult } from '@/utils/types/result-types.ts'
import { orderBy } from 'lodash'
import { PanelBase } from './'

export interface NewsPanelProps {
  isFullscreen?: boolean
  position?: 'left' | 'center' | 'right'
  listView?: boolean
}

export function NewsPanel({
  isFullscreen = false,
  position,
  listView = true,
}: NewsPanelProps) {
  const {
    categorized: { publicationResults: filteredResults },
  } = useTimelineFilteredResults()
  const unfilteredResults = usePublicationResults()
  const newsResults = isFullscreen ? filteredResults : unfilteredResults
  const sortPreference = usePostSortPreference()
  const setSortPreference = useSetPostSortPreference()
  const sortedResults = React.useMemo(() => {
    return orderBy(newsResults, sortPreference || undefined, 'desc')
  }, [newsResults, sortPreference])

  const [isListView, setIsListView] = React.useState(false)

  return (
    <PanelBase
      titleShort="News"
      titleLong="All News"
      isFullscreen={isFullscreen}
      isolateType={isPublicationResult}
      link={ROUTES.news}
      position={position}
      sortPreference={sortPreference}
      setSortPreference={setSortPreference}
      menu={
        <Button.Group>
          <ActionIcon
            onClick={() => setIsListView(false)}
            variant={isListView ? 'subtle' : 'filled'}
          >
            <Icon name="grid_view" />
          </ActionIcon>
          <ActionIcon
            onClick={() => setIsListView(true)}
            variant={isListView ? 'filled' : 'subtle'}
          >
            <Icon name="reorder" />
          </ActionIcon>
        </Button.Group>
      }
    >
      {isFullscreen ? (
        isListView ? (
          <Stack maw={800}>
            {sortedResults.map((result) => (
              <NewsResult
                key={result.documentId}
                result={result}
                isFullscreen={isFullscreen}
                listView={listView}
              />
            ))}
          </Stack>
        ) : (
          <SimpleGrid cols={{ base: 3, lg: 4 }}>
            {sortedResults.map((result) => (
              <NewsResult
                key={result.documentId}
                result={result}
                isFullscreen={isFullscreen}
                listView={listView}
              />
            ))}
          </SimpleGrid>
        )
      ) : (
        <Stack flex={1}>
          {sortedResults.slice(0, 5).map((result) => (
            <NewsResult
              key={result.documentId}
              result={result}
              isFullscreen={isFullscreen}
              listView={listView}
            />
          ))}
        </Stack>
      )}
    </PanelBase>
  )
}
