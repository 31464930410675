import React from 'react'
import { Stack } from '@mantine/core'
import { useMap } from '@mantine/hooks'
import type { ContextModalProps } from '@mantine/modals'
import {
  NON_DEFAULT_CATEGORIES,
  PROVIDER_COLUMN_TEMPLATE,
  type ProviderSource,
} from '@/components/filters/providers/use-providers'
import { useConfigureSavedSearch } from '@/components/modals/SaveSearchModal/SaveSearchPanel/use-configure-saved-search.ts'
import type {
  CreateTerrapinParams,
  TerrapinLocation,
} from '@/hooks/use-terrapin'
import { useSetTimePreset } from '@/stores/filters-store'
import { TimePreset } from '@/utils/types/filter-types'
import { SaveSearchPanel } from './SaveSearchPanel'
import { TerrapinLocationPanel } from './TerrapinLocationPanel'

export interface SaveSearchSettingsModalParams {
  params?: CreateTerrapinParams
  refId?: string
  isNew?: boolean
  providers?: ProviderSource[]
}

enum SaveSearchModalPanel {
  SAVE_SEARCH = 'SAVE_SEARCH',
  TERRAPIN_LOCATION = 'TERRAPIN_LOCATION',
}

export function SaveSearchModal({
  context,
  id,
  innerProps: { refId, params: savedConfig, providers },
}: ContextModalProps<SaveSearchSettingsModalParams>) {
  const {
    name,
    setName,
    selectedCollectionId,
    setSelectedCollectionId,
    isSaving,
    handleSave,
    expirationDate,
    setExpirationDate,
    emailNotification,
    setEmailNotification,
    backfill,
    setBackfill,
    locations,
    setLocations,
  } = useConfigureSavedSearch({ savedConfig, refId })
  const setTimePreset = useSetTimePreset()
  const [isRecurring, setIsRecurring] = React.useState<boolean>(
    !!(savedConfig?.backfill || savedConfig?.emailNotification),
  )
  const isNew = refId === undefined
  // Prepopulates the providersMap, which holds the state of which providers are selected
  // savedConfig?.filters contains a number of filters, one of which are the selected providers
  // For each column, we are prepoulated the selected providers base on savedConfig
  const providersMap = useMap(
    (() => {
      return PROVIDER_COLUMN_TEMPLATE.map((column) => [
        column.label,
        (providers ?? ([] as ProviderSource[]))
          .filter((provider) => {
            if (column.categories.includes(provider.category)) {
              return true
            }

            if (
              column.categories.includes('*') &&
              !NON_DEFAULT_CATEGORIES.includes(provider.category)
            ) {
              return true
            }

            return false
          })
          .filter(
            (provider) =>
              isNew ||
              savedConfig?.filters
                ?.find((filter) => filter.type === 'PROVIDER')
                ?.ids.includes(provider.source),
          )
          .map((provider) => provider.source),
      ])
    })(),
  )

  const [panel, setPanel] = React.useState(SaveSearchModalPanel.SAVE_SEARCH)

  // AreaOfInterest
  const [fileName, setFileName] = React.useState<string>()

  const handleLocationsSave = (locations: TerrapinLocation[]) => {
    setLocations(locations)
    setPanel(SaveSearchModalPanel.SAVE_SEARCH)
  }

  React.useEffect(() => {
    setTimePreset(TimePreset.None, null, null)
  }, [])

  return (
    <Stack>
      {panel == SaveSearchModalPanel.SAVE_SEARCH && (
        <SaveSearchPanel
          isNew={isNew}
          isRecurring={isRecurring}
          setIsRecurring={setIsRecurring}
          name={name}
          setName={setName}
          selectedCollectionId={selectedCollectionId}
          setSelectedCollectionId={setSelectedCollectionId}
          isSaving={isSaving}
          handleSave={handleSave}
          expirationDate={expirationDate}
          setExpirationDate={setExpirationDate}
          emailNotification={emailNotification}
          setEmailNotification={setEmailNotification}
          backfill={backfill}
          setBackfill={setBackfill}
          locations={locations}
          setLocations={setLocations}
          fileName={fileName}
          setFileName={setFileName}
          refId={refId}
          savedConfig={savedConfig}
          onEditLocations={() =>
            setPanel(SaveSearchModalPanel.TERRAPIN_LOCATION)
          }
          onClose={() => context.closeModal(id)}
          providersMap={providersMap}
          providers={providers ?? []}
        />
      )}
      {panel == SaveSearchModalPanel.TERRAPIN_LOCATION && (
        <TerrapinLocationPanel
          locations={locations}
          onSave={handleLocationsSave}
          onCancel={() => setPanel(SaveSearchModalPanel.SAVE_SEARCH)}
        />
      )}
    </Stack>
  )
}
