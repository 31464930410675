import { useRef } from 'react'
import { ActionIcon, Stack, Tooltip } from '@mantine/core'
import { Icon } from '@/components/lib/Icon'
import { useNavigateToMap } from '@/features/AppRouting/hooks'
import {
  useMapboxMap,
  useOrthoLayers,
  usePointData,
} from '@/features/DantiMap/hooks'
import { ICONS } from '@/utils/constants'
import { captureEvent, POSTHOG_EVENTS } from '@/utils/posthog'
import { classes } from '@/utils/ui-helpers'
import { useEmbeddedInitialBehavior, useImageryExtents } from './hooks'

import styles from '@/features/DantiMap/danti-map.module.css'

export const EmbeddedMap = () => {
  const mapContainer = useRef<HTMLDivElement | null>(null)
  const map = useRef<mapboxgl.Map | null>(null)
  const navigateToMap = useNavigateToMap()

  useMapboxMap(map, mapContainer)
  useEmbeddedInitialBehavior(map)
  usePointData(map, 'Embedded', true)
  useImageryExtents({ map })
  useOrthoLayers(map)

  const expandMap = () => {
    captureEvent(POSTHOG_EVENTS.EXPLORE.EXPAND_MAP)
    navigateToMap()
  }

  return (
    <Stack className={styles.embeddedMap}>
      <Tooltip
        label="Expand Map"
        position="right"
        className={styles.embeddedMapTooltip}
        bg="rgba(97, 97, 97, 1)"
        withArrow
        arrowSize={7}
      >
        <ActionIcon
          onClick={expandMap}
          className={styles.embeddedMapExpandButton}
          radius="xs"
        >
          <Icon name={ICONS.fullscreen} />
        </ActionIcon>
      </Tooltip>
      <div
        ref={mapContainer}
        className={classes('map-container', styles.embeddedMapContainer)}
      />
    </Stack>
  )
}
