import {
  ActionIcon,
  Avatar,
  BackgroundImage,
  Box,
  Divider,
  Group,
  NavLink,
  Stack,
} from '@mantine/core'
import {
  commercialBackgroundImages,
  govBackgroundImages,
} from '@/assets/images'
import { Icon } from '@/components/lib/Icon'
import { useFeatureFlag } from '@/hooks/use-feature-flag'
import { useAvatarDetails } from '@/stores/user-store'
import { FEATURE_FLAG_VALUES } from '@/utils/constants'
import { navItems } from '../Header'

export interface NavbarProps {
  opened: boolean
  toggle: () => void
}

export function Navbar({ opened, toggle }: NavbarProps) {
  const { avatarBackgroundColor, userInitials } = useAvatarDetails()
  const isCommercial = useFeatureFlag(FEATURE_FLAG_VALUES.commercial)
  const backgroundImages = isCommercial
    ? commercialBackgroundImages
    : govBackgroundImages

  return (
    <Stack gap={5}>
      <Box h={100}>
        <BackgroundImage
          src={backgroundImages[0]}
          pos="absolute"
          h={100}
          top={0}
          left={0}
          style={{
            zIndex: -1,
            backgroundImage: 'linear-gradient(180deg, #0000 60%, #000b 100%)',
          }}
        />
        <Group justify="space-between" align="start">
          <Avatar
            variant="filled"
            color={avatarBackgroundColor}
            size="lg"
            radius="50%"
          >
            {userInitials}
          </Avatar>
          <ActionIcon variant="transparent" color="white" onClick={toggle}>
            <Icon name="close" size="xl" />
          </ActionIcon>
        </Group>
      </Box>
      {navItems.map(({ id, icon, label, path }) => (
        <NavLink
          key={id}
          href={path}
          label={label}
          leftSection={<Icon name={icon} />}
        />
      ))}
      <Stack bottom={16} pos="absolute" w="100%">
        {opened && <Divider />}
        <NavLink
          key="sign-out"
          href="#sign-out"
          label="Sign Out"
          leftSection={<Icon name="logout" />}
        />
      </Stack>
    </Stack>
  )
}
