import { ALL_POST_FILTER_TYPES } from '@/stores/results-filter-store/constants'
import type { PostFilterType } from '@/stores/results-filter-store/types'
import {
  extractAssetName,
  extractCompanyName,
  extractSensorName,
} from '@/stores/results-filter-store/utils'
import type { ImageryResult } from '@/utils/types/result-types'
import { useResultsFilterStore } from './store'
import { useTimelineFilteredResults } from './use-timeline-filtered-results'

type UsePostfilterImageResultsProps = {
  excludeType?: PostFilterType
}
export const usePostfilterImageResults = ({
  excludeType,
}: UsePostfilterImageResultsProps = {}) => {
  const {
    categorized: { imageResults },
  } = useTimelineFilteredResults()
  const {
    omittedSources,
    omittedRepos,
    omittedAssets,
    omittedSensors,
    qualityCeil,
    cloudCeil,
  } = useResultsFilterStore()

  const predicateByType: {
    [key in PostFilterType]: (ir: ImageryResult) => boolean
  } = {
    source: (ir) => !omittedSources.includes(extractCompanyName(ir) || ''),
    repo: (ir) => !omittedRepos.includes(ir.source),
    asset: (ir) => !omittedAssets.includes(extractAssetName(ir) || ''),
    sensor: (ir) => !omittedSensors.includes(extractSensorName(ir)),
    quality: (ir) => {
      const q = ir.properties._dQuality
      if (!q) {
        return true //Don't filter out images that aren't right
      }
      const qualityMap = {
        1: 0.5,
        2: 1,
        3: 3,
      } as Record<number, number>
      return qualityCeil ? q <= qualityMap[qualityCeil] : true
    },
    cloud: (ir) => {
      const c = ir.properties._dCloudCover
      if (!c) {
        return true
      }
      const cloudMap = {
        1: 20,
        2: 40,
        3: 60,
        4: 80,
      } as Record<number, number>
      return cloudCeil ? c <= cloudMap[cloudCeil] : true
    },
  }

  const filterTypes = ALL_POST_FILTER_TYPES.filter((ft) => ft !== excludeType)
  const results = filterTypes.reduce((filteredResults, currentFilterType) => {
    return filteredResults.filter(predicateByType[currentFilterType])
  }, imageResults)
  return results
}
