import React from 'react'
import { useNavigate } from 'react-router-dom'
import { ActionIcon, Group, Stack, Title } from '@mantine/core'
import { QueryHeader } from '@/components/layout/QueryHeader'
import { Icon } from '@/components/lib/Icon'
import { ImageResultPostfilterPopup } from '@/components/lib/results/results-postfilter/ImageResultPostfilterPopup'
import { ResultsDateFilter } from '@/components/lib/results/results-postfilter/ResultsDateFilter'
import { ResultsTimeline } from '@/components/lib/results/results-timeline/ResultsTimeline'
import type { SortPreferenceOption } from '@/stores/queries-store'
import type { ResultWithId } from '@/utils/types/result-types'
import { SortSelect } from './SortSelect'

interface FullScreenPanelHeaderProps {
  isolateType: (result: ResultWithId) => boolean
  titleLong: string
  link: string
  menu?: React.ReactNode
  sortPreference?: SortPreferenceOption
  setSortPreference?: (sortPreference: SortPreferenceOption) => void
}

export function FullScreenPanelHeader({
  isolateType,
  titleLong,
  link,
  menu,
  sortPreference,
  setSortPreference,
}: FullScreenPanelHeaderProps) {
  const navigate = useNavigate()

  React.useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <Stack gap={0}>
      <Stack gap={0}>
        <QueryHeader fixed />
        <ActionIcon
          mt="lg"
          title="Go back"
          variant="transparent"
          onClick={() => navigate(-1)}
        >
          <Icon name="arrow_circle_left" size="xl" />
        </ActionIcon>
      </Stack>
      <Group mb="sm" gap={6}>
        <Title size={80}>{titleLong}</Title>
        <div style={{ flexGrow: 1 }} />
        <ResultsTimeline width={500} isSmall isolateType={isolateType} />
        {menu}
        {link === '/images' && <ImageResultPostfilterPopup />}
        <ResultsDateFilter />
        <SortSelect
          sortPreference={sortPreference}
          setSortPreference={setSortPreference}
        />
      </Group>
    </Stack>
  )
}
