import { getFireCategoryCardProps } from '@/api/results'
import { GeometryCard } from '@/features/ResultCards/ReferenceCards/components'
import { RESULT_CATEGORY_COLORS } from '@/utils/constants'
import type { FireResult } from '@/utils/types/result-types'

export const FireReferenceCard = ({ fire }: { fire: FireResult }) => {
  const color = RESULT_CATEGORY_COLORS.fire.toLowerCase().slice(1)
  return (
    <GeometryCard
      {...getFireCategoryCardProps(fire)}
      title="Wildfire"
      color={color}
    />
  )
}
