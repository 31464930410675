import { type MutableRefObject, useEffect } from 'react'
import { useCategorizedResults } from '@/api/results'
import { emitter, EVENTS } from '@/events'
import { formatMapboxStyle } from '@/features/DantiMap/utils'
import { type BaseLayerOption, useBaseLayer } from '@/stores/map-store'
import type { DrawEvent } from '@mapbox/mapbox-gl-draw'
import mapboxgl from 'mapbox-gl'
import { useTransformMapRequest } from './use-transform-map-request'

type MapboxMapProps = Partial<mapboxgl.MapboxOptions> & {
  showMapControls?: boolean
  baseLayer?: BaseLayerOption
  bounds?: mapboxgl.LngLatBoundsLike
}

export const useMapboxMap = (
  map: MutableRefObject<mapboxgl.Map | null>,
  mapContainer: MutableRefObject<HTMLDivElement | null>,
  options?: MapboxMapProps,
) => {
  const {
    showMapControls = false,
    bounds,
    baseLayer,
    ...mapboxProps
  } = options ?? {}
  const storeBaseLayer = useBaseLayer()
  const categorized = useCategorizedResults()

  const transformRequest =
    useTransformMapRequest() as mapboxgl.TransformRequestFunction

  useEffect(() => {
    // initialize map only once
    if (map.current || !mapContainer.current) {
      return
    }

    map.current = new mapboxgl.Map({
      container: mapContainer.current,
      style: formatMapboxStyle(baseLayer ?? storeBaseLayer),
      bounds: bounds ?? [-102.051744, 36.993016, -94.588413, 40.003162],
      pitchWithRotate: false,
      touchPitch: false,
      dragRotate: false,
      touchZoomRotate: false,
      transformRequest,
      ...mapboxProps,
    })
    map.current.on('load', () => {
      emitter.emit(EVENTS.map.load)
    })
    map.current.on('draw.create', (event: DrawEvent) => {
      emitter.emit(EVENTS.map.drawCreated, event)
    })
    map.current.on('draw.update', (event: DrawEvent) => {
      emitter.emit(EVENTS.map.drawUpdated, event)
    })
    map.current.on('styledata', () => {
      emitter.emit(EVENTS.map.styleLoaded)
    })
    map.current.on('style.load', () => {
      emitter.emit(EVENTS.map.styleLoaded)
    })

    if (showMapControls) {
      map.current.addControl(
        new mapboxgl.NavigationControl({
          visualizePitch: false,
          showCompass: false,
        }),
        'bottom-left',
      )
    }

    map.current.doubleClickZoom.disable()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    transformRequest,
    storeBaseLayer,
    map,
    mapContainer,
    categorized.imageResults,
    showMapControls,
  ])
}
