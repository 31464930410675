import { SCORE_COLORS } from '@/utils/constants'
import { RISK_ICONS } from '@/utils/svg-icons'
import type {
  AttomPermit,
  HazardPropertyResult,
  HazardRisk,
  HazardRiskResult,
} from '@/utils/types/result-types'

export const getPermitDescription = (result: AttomPermit) => {
  const { description } = result
  let out = result.description || ''
  if (out.startsWith(': ')) {
    out = description.slice(2)
  }
  return out.toLowerCase()
}

export const getPropertyDataFields = (item: HazardPropertyResult) => {
  const USDollar = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  })
  const listPrice = item.properties.listPrice
    ? USDollar.format(Number.parseInt(item.properties.listPrice))
    : 'N/A'
  return [
    { title: 'bedrooms', value: item.properties.bedroomsTotal },
    { title: 'bathrooms', value: item.properties.bathsTotal },
    { title: 'type', value: item.properties.useCode },
    { title: 'hvac', value: item.properties.coolingFeatures },
    { title: 'list sale price', value: listPrice },
    { title: 'list date', value: item.properties.listDate },
    {},
  ]
}

export const getHighestRisks = (result: HazardRiskResult) => {
  const { risks } = result.properties
  const riskKeys = Object.keys(risks)
  const sortedRiskKeys = riskKeys.sort((a: string, b: string) => {
    const scoreA = risks[a]?.score || ''
    const scoreB = risks[b]?.score || ''
    return scoreB.localeCompare(scoreA)
  })
  const highestRisk = risks[sortedRiskKeys[0]]
  return riskKeys.filter((riskKey) => {
    const risk = result.properties.risks[riskKey]
    return risk.score === highestRisk.score
  })
}

export const getRiskColor = (risk: { score?: string }) =>
  risk.score ? SCORE_COLORS[risk.score] : SCORE_COLORS.F

export const getRiskCardDetails = (risk: HazardRisk) => ({
  title: risk.title,
  icon: RISK_ICONS[risk.key as keyof typeof RISK_ICONS],
  iconColor: getRiskColor(risk),
  leftDetail: risk.score,
  rightDetail: risk.risk,
})
