import type { CollectionSortType } from '@/api/collections'
import { createStore } from '@/utils/create-store'
import { CategoryTypes } from '@/utils/types/result-types'

export interface CollectionItemsState {
  currentPage: number
  nextToken: string
  previousToken: string
  sort: CollectionSortType
  searchTerm: string
  search: (term: string) => void
  selectedCategories: CategoryTypes[]
  setCurrentPage: (value: number) => void
  setNextToken: (value: string) => void
  setPreviousToken: (value: string) => void
  setSort: (value: CollectionSortType) => void
  setSearchTerm: (value: string) => void
  setSelectedCategories: (value: CategoryTypes[]) => void
  toNextPage: (next: string, maxPages: number) => void
  toPreviousPage: (previous: string) => void
  toggleSelectedCategory: (category: CategoryTypes) => void
  reset: () => void
}

export const initialState = {
  currentPage: 0,
  nextToken: '',
  previousToken: '',
  sort: '' as CollectionSortType,
  searchTerm: '',
  selectedCategories: [
    CategoryTypes.IMAGE,
    CategoryTypes.PUBLICATION,
    CategoryTypes.SOCIAL_MEDIA,
    CategoryTypes.DATA,
    CategoryTypes.FILE,
    CategoryTypes.ANALYTIC,
  ],
} as Partial<CollectionItemsState>

export const useCollectionItemsStore = createStore<CollectionItemsState>({
  initialState,
  storageKey: 'CollectionItems',
  version: 0.01,
  actions: (set, get): Partial<CollectionItemsState> => ({
    setCurrentPage: (value) => set({ currentPage: value }),
    setNextToken: (value) => set({ nextToken: value }),
    setPreviousToken: (value) => set({ previousToken: value }),
    setSort: (value) => set({ sort: value }),
    setSearchTerm: (value) => set({ searchTerm: value }),
    setSelectedCategories: (value) => set({ selectedCategories: value }),
    reset: () => set(initialState),
    search: (term: string) =>
      set({
        nextToken: '',
        previousToken: '',
        currentPage: 0,
        searchTerm: term,
      }),
    toNextPage: (next: string, maxPages: number) => {
      const currentPage = get().currentPage as number
      set({
        currentPage: Math.min(maxPages, currentPage + 1),
        nextToken: next,
        previousToken: '',
      })
    },
    toPreviousPage: (previous: string) => {
      const currentPage = get().currentPage as number
      set({
        currentPage: Math.max(0, currentPage - 1),
        nextToken: '',
        previousToken: previous,
      })
    },
    toggleSelectedCategory: (category: CategoryTypes) => {
      const selectedCategories = get().selectedCategories as CategoryTypes[]
      if (selectedCategories.includes(category)) {
        set({
          selectedCategories: selectedCategories.filter((c) => c !== category),
        })
      } else {
        set({ selectedCategories: [...selectedCategories, category] })
      }
    },
  }),
})
