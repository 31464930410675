import type { SymbolName } from '@/utils/types/material-icons'
import { snakeCase } from 'lodash'

export const {
  VITE_APP_ENV: appEnv,
  VITE_API_URL: apiUrl,
  VITE_SEARCH_API_URL: searchApiUrl,
  VITE_SERVICE_API_URL: serviceApiUrl,
  VITE_GRAPHQL_WS: graphWs,
  VITE_OIDC_AUTHORITY: oidcAuthority,
  VITE_OIDC_CLIENT_ID: oidcClientId,
  VITE_OIDC_REDIRECT_URI: oidcRedirectUri,
  VITE_MAPBOX_TOKEN: mapboxToken,
  VITE_STRIPE_TABLE_ID: stripeTableId,
  VITE_STRIPE_PUBLIC_KEY: stripePublicKey,
  VITE_STRIPE_BILLING_URL: stripeBillingUrl,
}: ImportMetaEnv = import.meta.env

export const isLocal =
  appEnv === 'local' || window.location.hostname === 'localhost'
export const isDevelopment = appEnv === 'dev'
export const isStaging = appEnv === 'stage'
export const isDemonstration = appEnv === 'demo'
export const isProduction = appEnv === 'prod'

export const isGov = appEnv === 'gov'

export const REDIRECT_COOKIE_NAME = 'RedirectAfterLogin'
export const REDIRECT_PATH = '/redirect'

export const ROUTES = {
  account: '/account',
  password: '/account/password',
  users: '/account/users',
  help: '/help',
  billing: '/account/billing',
  planSelection: '/plan-selection',
  login: '/log-in',
  accessDenied: '/access-denied',
  error: '/error',
  root: '/',
  results: '/results',
  resultsItem: '/results/:itemId',
  overview: '/overview',
  news: '/news',
  images: '/images',
  fires: '/fires',
  ships: '/ships',
  social: '/social',
  acceptShare: '/accept-share',
  acceptShareToken: '/accept-share/:collectionId/:shareToken',
  collections: '/collections',
  collectionDetail: '/collections/:collectionId',
  collectionItemDetail: '/collections/:collectionId/items/:itemId',
  collectionItemDownload: '/collections/:collectionId/items/:itemId/download',
  history: '/history',
  map: '/map',
  mapItem: '/map/:itemId',
  address: '/address',
  addressPath: '/address/:mock?',
  terrapin: '/terrapin',
  terrapinItem: '/terrapin/:terrapinItemId',
  admin: '/admin',
  allProperty: '/properties',
  timeline: '/timeline',
  notFound: '/404',
}

export const DATA_CATEGORIES = {
  IMAGE: 'IMAGE',
  DATA: 'DATA',
  PUBLICATION: 'WEB_ARTICLE',
  SOCIAL_MEDIA: 'SOCIAL_MEDIA',
  INSIGHT: 'INSIGHT',
  RELATED_IMAGERY: 'RELATED_IMAGERY',
  ANALYTIC: 'ANALYTIC',
  PROPERTY: 'PROPERTY',
  WEB_ARTICLE: 'WEB_ARTICLE',
  FILE: 'FILE',
} as const

export const DATA_SOURCES = {
  BLACKSKY: 'BLACKSKY',
  VEXCEL: 'VEXCEL',
  UP42: 'UP42',
  SENTINEL: 'SENTINEL_HUB',
  CAPELLA: 'CAPELLA',
  SATELLOGIC: 'SATELLOGIC',
  UMBRA: 'UMBRA',
} as const

export const RESULT_CATEGORIES = {
  INSIGHT: 'INSIGHT',

  ATTOM: 'ATTOM',
  FIRE: 'FIRE',
  HAZARD_PROPERTY: 'HAZARD_PROPERTY',
  HAZARD_RISK: 'HAZARD_RISK',
  IMAGE: 'IMAGE',
  PUBLICATION: 'WEB_ARTICLE',
  REGRID: 'REGRID',
  SHIP: 'SHIP',
  SOCIAL_MEDIA: 'SOCIAL_MEDIA',

  RELATED_IMAGERY: 'RELATED_IMAGERY',
  ANALYTIC: 'ANALYTIC',
  PROPERTY: 'PROPERTY',
  WEB_ARTICLE: 'WEB_ARTICLE',
  VESSEL: 'VESSEL',
  PORT: 'PORT',
  FILE: 'FILE',
  DATA: 'DATA',
} as const

export const EMPTY_RESULT_IDS = {
  IMAGE: [] as string[],
  DATA: [] as string[],
  WEB_ARTICLE: [] as string[],
  SOCIAL_MEDIA: [] as string[],
  INSIGHT: [] as string[],
  RELATED_IMAGERY: [] as string[],
  PROPERTY: [] as string[],
} as const

export const EMPTY_CATEGORIZED_RESULTS = {
  imageResults: [],
  dataResults: [],
  publicationResults: [],
  socialMediaResults: [],
  insightResults: [],
  propertyResults: [],
} as const

export const SCORE_COLORS = {
  F: '#C62828',
  D: '#ED6C02',
  C: '#DFA702',
  B: '#829A24',
  A: '#2E7D32',
} as { [key: string]: string }

export const SOCIAL_COLORS = {
  facebook: '#0091EA',
  youtube: '#FF0000',
  instagram: '#E1306C',
  reddit: '#FF4500',
  vk: '#4C75A3',
  weibo: '#E6162D',
  toutiao: '#FF8247',
  telegram: '#0088cc',
} as { [key: string]: string }

export const RESULT_CATEGORY_COLORS = {
  attom: '#834DD3',
  fire: '#D32F2f',
  ship: '#00A5B0',
  news: '#ffa726',
  social: '#4CAF50',
}

export const ICONS = {
  add: 'add',
  addCircleOutline: 'add_circle_outline',
  anchor: 'anchor',
  analytics: 'analytics',
  arrowBack: 'arrow_back',
  arrowCircleLeft: 'arrow_circle_left',
  arrowDropDown: 'arrow_drop_down',
  calendarClock: 'calendar_clock',
  calendarMonth: 'calendar_month',
  calendarToday: 'calendar_today',
  chartData: 'chart_data',
  checkCircle: 'check_circle',
  chevronRight: 'chevron_right',
  clear: 'clear',
  close: 'close',
  construction: 'construction',
  delete: 'delete',
  deleteOutline: 'delete_outline',
  download: 'download',
  expandMore: 'expand_more',
  fileUpload: 'file_upload',
  filterAlt: 'filter_alt',
  folder: 'folder',
  folderSpecial: 'folder_special',
  fullscreen: 'fullscreen',
  gppGood: 'gpp_good',
  gridView: 'grid_view',
  highlightOff: 'highlight_off',
  history: 'history',
  house: 'house',
  info: 'info',
  iosShare: 'ios_share',
  keyboardArrowDown: 'keyboard_arrow_down',
  keyboardArrowLeft: 'keyboard_arrow_left',
  keyboardArrowRight: 'keyboard_arrow_right',
  keyboardArrowUp: 'keyboard_arrow_up',
  link: 'link',
  list: 'list',
  localFireDepartment: 'local_fire_department',
  logout: 'logout',
  map: 'map',
  modeHeat: 'mode_heat',
  moreHoriz: 'more_horiz',
  moreVert: 'more_vert',
  navigateBefore: 'navigate_before',
  newspaper: 'newspaper',
  openInNew: 'open_in_new',
  partlyCloudyDay: 'partly_cloudy_day',
  peopleAlt: 'people_alt',
  person: 'person',
  questionMark: 'question_mark',
  reorder: 'reorder',
  rightPanelClose: 'right_panel_close',
  rightPanelOpen: 'right_panel_open',
  satellite: 'satellite',
  satelliteAlt: 'satellite_alt',
  save: 'save',
  schedule: 'schedule',
  search: 'search',
  settings: 'settings',
  sort: 'sort',
  spaceDashboard: 'space_dashboard',
  speed: 'speed',
  update: 'update',
  uploadFile: 'upload_file',
  viewList: 'view_list',
  warningAmber: 'warning_amber',
  wbCloudy: 'wb_cloudy',
  wbSunny: 'wb_sunny',
} as Record<string, SymbolName>

// Result menu options
export const MENU_OPTION_KEYS = {
  addToCollection: 'addToCollection',
  visitArticle: 'visitArticle',
  viewDetails: 'viewDetails',
  downloadImage: 'downloadImage',
  viewOnMap: 'viewOnMap',
  sendToAnalytics: 'sendToAnalytics',
  share: 'share',
} as const

export const MENU_OPTION_LABELS = {
  [MENU_OPTION_KEYS.addToCollection]: 'Add to Saved Search',
  [MENU_OPTION_KEYS.visitArticle]: 'Visit Article',
  [MENU_OPTION_KEYS.downloadImage]: 'Download',
  [MENU_OPTION_KEYS.viewOnMap]: 'View on Map',
  [MENU_OPTION_KEYS.sendToAnalytics]: 'Analyze',
  [MENU_OPTION_KEYS.viewDetails]: 'View Details',
  [MENU_OPTION_KEYS.share]: 'Share',
} as const

export const MENU_OPTION_ICONS = {
  [MENU_OPTION_KEYS.addToCollection]: ICONS.add,
  [MENU_OPTION_KEYS.visitArticle]: ICONS.openInNew,
  [MENU_OPTION_KEYS.downloadImage]: ICONS.download,
  [MENU_OPTION_KEYS.viewOnMap]: ICONS.map,
  [MENU_OPTION_KEYS.sendToAnalytics]: ICONS.chartData,
  [MENU_OPTION_KEYS.share]: ICONS.iosShare,
  [MENU_OPTION_KEYS.viewDetails]: ICONS.info,
} as const

export const FUTURE_MENU_OPTIONS = [
  MENU_OPTION_KEYS.sendToAnalytics,
  MENU_OPTION_KEYS.share,
] as const

export const FUTURE_OPTION_TITLE = 'Coming Soon'

export const DEFAULT_ACTIONS_TO_HIDE = [
  MENU_OPTION_KEYS.addToCollection,
  MENU_OPTION_KEYS.visitArticle,
  MENU_OPTION_KEYS.downloadImage,
  MENU_OPTION_KEYS.share,
  MENU_OPTION_KEYS.sendToAnalytics,
]

export const COLLECTIBLE_ACTIONS_TO_HIDE = [
  MENU_OPTION_KEYS.visitArticle,
  MENU_OPTION_KEYS.downloadImage,
  MENU_OPTION_KEYS.share,
  MENU_OPTION_KEYS.sendToAnalytics,
]
export const MAP_BASE_LAYERS = {
  satellite: 'danti-ai/clza3n565007401oh3ic9fmix',
  dantiStreets: 'danti-ai/clygagdfr00w601r4bu5nd8l3',
} as const

export const SAVED_SEARCH_MODES = {
  search: 'search',
  feed: 'feed',
} as const

export const FEATURE_FLAG_KEYS = {
  timeline: 'timeline',
  insightFeedback: 'insightFeedback',
  fauxAnalytic: 'fauxAnalytic',
  accountManagement: 'accountManagement',
  passwordManagement: 'passwordManagement',
  profileManagement: 'profileManagement',
  userManagement: 'userManagement',
  dynamicProviders: 'dynamicProviders',
  places: 'places',
  showScores: 'showScores',
  commercial: 'commercial',
} as const
export type FeatureFlagKeys = keyof typeof FEATURE_FLAG_KEYS

export const FEATURE_FLAG_VALUES = Object.fromEntries(
  Object.values(FEATURE_FLAG_KEYS).map((key) => [key, key]),
) as Record<FeatureFlagKeys, FeatureFlagKeys>

export type FeatureFlagValues = ValueOf<typeof FEATURE_FLAG_VALUES>

export const FEATURE_FLAG_ENV_VARIABLES = Object.fromEntries(
  Object.values(FEATURE_FLAG_VALUES).map((value) => {
    const envKey = snakeCase(value).toUpperCase()
    return [value, `VITE_${envKey}`]
  }),
) as Record<FeatureFlagValues, string>

export const FIRE_CONFIDENCE_LEVELS = {
  n: 'Nominal',
  l: 'Low',
  h: 'High',
} as const
