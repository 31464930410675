import { useEffect, useRef } from 'react'
import { ActionIcon } from '@mantine/core'
import { createStaticMapUrl } from '@/api/mapbox'
import { Icon } from '@/components/lib/Icon'
import { useBackNavigation } from '@/features/AppRouting/hooks'
import { useMapboxMap } from '@/features/DantiMap/hooks'
import { formatMapboxStyle } from '@/features/DantiMap/utils'
import { useBaseLayer, useSetBaseLayer } from '@/stores/map-store'
import { ICONS, MAP_BASE_LAYERS } from '@/utils/constants'
import type { ImageryResult } from '@/utils/types/result-types'
import { classes } from '@/utils/ui-helpers'
import { useAnalyticLayer, useSingleOrthoLayer } from './hooks'

import styles from '@/features/DantiMap/danti-map.module.css'

interface SingleImageMapProps {
  isFullScreen?: boolean
  item: ImageryResult
  className?: string
  style?: React.CSSProperties
  tallMap?: boolean
}
export function SingleImageMap({
  className,
  isFullScreen,
  item,
  style,
  tallMap,
}: SingleImageMapProps) {
  const mapContainer = useRef<HTMLDivElement | null>(null)
  const map = useRef<mapboxgl.Map | null>(null)
  const baseLayer = useBaseLayer()
  const setBaseLayer = useSetBaseLayer()
  useMapboxMap(map, mapContainer)
  useSingleOrthoLayer(map, item)
  useAnalyticLayer(map, item)
  const goBack = useBackNavigation()

  useEffect(() => {
    map.current?.setStyle(formatMapboxStyle(baseLayer))
  }, [baseLayer, map])

  const imgSource = createStaticMapUrl({
    geometry: item.geometry,
    baseMap:
      baseLayer === MAP_BASE_LAYERS.dantiStreets
        ? MAP_BASE_LAYERS.satellite
        : MAP_BASE_LAYERS.dantiStreets,
    size: [100, 100],
  })

  const toggleLayer = () => {
    void setBaseLayer(
      baseLayer === MAP_BASE_LAYERS.dantiStreets
        ? MAP_BASE_LAYERS.satellite
        : MAP_BASE_LAYERS.dantiStreets,
    )
  }

  return (
    <div
      className={classes(
        styles.singleImageMap,
        className,
        tallMap ? styles.tallMap : '',
        isFullScreen ? styles.fullScreen : '',
      )}
      style={style}
    >
      {isFullScreen && (
        <ActionIcon onClick={goBack} className={styles.backButton}>
          <Icon name={ICONS.arrowBack} />
        </ActionIcon>
      )}
      <div
        ref={mapContainer}
        className={classes(
          'map-container',
          styles.mapContainer,
          isFullScreen ? styles.fullScreen : '',
        )}
      />
      <img
        src={imgSource}
        alt="map"
        className={styles.map}
        onClick={toggleLayer}
      />
    </div>
  )
}
