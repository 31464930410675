import { px, Spoiler, Stack, Text, useMantineTheme } from '@mantine/core'
import { useDisclosure } from '@mantine/hooks'
import { Icon } from '@/components/lib/Icon'
import { messages } from '@/features/InsightPanel/messages'
import type { InsightsPanelProps } from '@/features/InsightPanel/types'
import { wordBreak } from '@/utils/ui-helpers'
import ReactMarkdown from 'react-markdown'

import styles from './insight-panel.module.css'

const LINES_TO_SHOW = 4

export function EmbeddedInsightPanel({ result }: InsightsPanelProps) {
  const [isOpen, { toggle }] = useDisclosure()
  const hide = () => setTimeout(toggle, 200)

  const theme = useMantineTheme()
  const lineHeight =
    Number(theme.lineHeights.md) * (theme.scale * Number(px('1rem')))

  // Calculate height as `LINES_TO_SHOW` lines minus the height of gap
  const spoilerHeight =
    (LINES_TO_SHOW + 1) * lineHeight - Number(px(theme.spacing.sm))

  const buttonProps = {
    className: styles.button,
    size: 'sm',
    variant: 'transparent',
  }

  return (
    <Stack className={styles.panel}>
      <Text className={styles.title} lineClamp={3} title={result.title}>
        {wordBreak(result.title)}
      </Text>
      <Spoiler
        data-cy="insights-panel"
        maxHeight={spoilerHeight}
        className={styles.spoiler}
        showLabel={
          <a onClick={toggle} {...buttonProps}>
            {messages.READ_MORE} <Icon name="arrow_drop_down" />
          </a>
        }
        hideLabel={
          <a onClick={hide} {...buttonProps}>
            {messages.HIDE} <Icon name="arrow_drop_up" />
          </a>
        }
      >
        <Text
          lineClamp={isOpen ? 0 : LINES_TO_SHOW}
          className={styles.markdown}
          span
        >
          <ReactMarkdown>{result.description}</ReactMarkdown>
        </Text>
      </Spoiler>
    </Stack>
  )
}
