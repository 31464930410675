import { useMemo } from 'react'
import { SEARCH_CATEGORIES } from '@/api/results/constants'
import type { Results } from '@/api/results/types'
import { ingestTransforms } from '@/stores/data-transforms'
import { RESULT_CATEGORIES } from '@/utils/constants'
import type {
  AttomResult,
  HazardPropertyResult,
  HazardRiskResult,
  InsightResult,
  PropertyResult,
  RawSearchResult,
  RegridResult,
  ResultWithId,
} from '@/utils/types/result-types'
import { useInsightQuery } from './use-insight-query'
import { useSearchResults } from './use-search-query'

export const useResults = (): Results => {
  const insightResult = useInsightQuery()?.insight?.insight
  const results = useSearchResults()
  return useMemo(() => {
    const addIdToDocument = (result: RawSearchResult) => {
      return { ...result, id: result.properties._dId }
    }
    const loadDocuments = (
      category: string,
      rawDocuments: RawSearchResult[],
    ) => {
      const documents = rawDocuments.map(addIdToDocument)
      const uniqueDocuments = documents.filter(
        (document, index) =>
          documents.findIndex((d) => d.id === document.id) === index,
      )
      return uniqueDocuments.map(ingestTransforms[category]) as ResultWithId[]
    }

    const loadCategory = (category: string) => {
      const group = results.find(
        (result) => result.category.toUpperCase() === category,
      )
      return group ? loadDocuments(category, group.documents) : []
    }

    const categorized = Object.values(SEARCH_CATEGORIES).reduce(
      (object, category) => {
        object[category] = loadCategory(category)
        return object
      },
      {} as Record<string, ResultWithId[]>,
    )

    const propertyDocuments = loadCategory(RESULT_CATEGORIES.PROPERTY)
    const propertyByCategory = propertyDocuments.reduce(
      (object, property) => {
        const result = property as PropertyResult
        const category = result.properties.resultCategory as string
        if (!object[category]) object[category] = []
        object[category].push(result)
        return object
      },
      {} as Record<string, PropertyResult[]>,
    )
    categorized[SEARCH_CATEGORIES.ATTOM] = (propertyByCategory[
      SEARCH_CATEGORIES.ATTOM
    ] || []) as AttomResult[]
    categorized[SEARCH_CATEGORIES.HAZARD_PROPERTY] = (propertyByCategory[
      SEARCH_CATEGORIES.HAZARD_PROPERTY
    ] || []) as HazardPropertyResult[]
    categorized[SEARCH_CATEGORIES.HAZARD_RISK] = (propertyByCategory[
      SEARCH_CATEGORIES.HAZARD_RISK
    ] || []) as HazardRiskResult[]
    categorized[SEARCH_CATEGORIES.REGRID] = (propertyByCategory[
      SEARCH_CATEGORIES.REGRID
    ] || []) as RegridResult[]

    const insightResults = (
      insightResult
        ? loadDocuments(SEARCH_CATEGORIES.INSIGHT, [insightResult])
        : []
    ) as InsightResult[]
    categorized[SEARCH_CATEGORIES.INSIGHT] = insightResults
    return categorized as Results
  }, [insightResult, results])
}
