import {
  useSetHoveredExtentIds,
  useSetHoveredLayerId,
  useToggleOrthoLayerGroupId,
} from '@/stores/map-store'

const featureIds = (event: mapboxgl.MapLayerMouseEvent, key: string) => {
  if (event.features) {
    return event.features.map((f) => f.properties?.[key] as string | undefined)
  }
  return []
}

const isTruthy = (f: unknown): f is string => !!f

export const useEventListeners = () => {
  const toggleOrthoLayerGroupId = useToggleOrthoLayerGroupId()
  const setHoveredExtentIds = useSetHoveredExtentIds()
  const setHoveredLayerId = useSetHoveredLayerId()

  const onMouseover = (event: mapboxgl.MapLayerMouseEvent) => {
    event.target.getCanvas().style.cursor = 'pointer'
    if (event.features) {
      const ids = featureIds(event, 'documentId').filter(isTruthy)
      setHoveredExtentIds(ids)
    }
  }

  const onMousemove = (event: mapboxgl.MapLayerMouseEvent) => {
    event.target.getCanvas().style.cursor = 'pointer'

    if (event.features) {
      const id = featureIds(event, 'groupKey').find(isTruthy) ?? null
      setHoveredLayerId(id)
    }
  }

  const onMouseout = (event: mapboxgl.MapLayerMouseEvent) => {
    event.target.getCanvas().style.cursor = 'default'
    setHoveredLayerId(null)
  }

  const onClick = (event: mapboxgl.MapLayerMouseEvent) => {
    if (event.features) {
      const id = featureIds(event, 'groupKey').find(isTruthy) ?? null
      if (id) {
        toggleOrthoLayerGroupId(id)
      }
    }
  }

  return {
    'extent-outlines': {
      handleMouseover: onMouseover,
    },
    'extent-fills': {
      handleMousemove: onMousemove,
      handleMouseout: onMouseout,
      handleClick: onClick,
    },
  }
}
