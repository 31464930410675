import { Box, Grid, Paper, Stack } from '@mantine/core'
import type { CollectionItem } from '@/api/collections/types.ts'
import { CollectionItemGridResult } from './CollectionItemGridResult'

export interface CollectionDetailGridProps {
  items: CollectionItem[]
  onClick: (id: string) => void
}

export function CollectionDetailGrid({ items }: CollectionDetailGridProps) {
  const footerHeight = 100
  const padding = 30

  return (
    <Grid
      columns={12}
      gutter="sm"
      mt="lg"
      style={{ overflow: 'initial' }}
      pb={footerHeight + padding}
    >
      {items.map((item) => (
        <Grid.Col key={item.id} span={{ base: 6, xs: 4, sm: 4, md: 4, lg: 3 }}>
          <Paper style={{ height: '100%' }}>
            <Stack justify="space-between" h="100%">
              <div>
                <Box style={{ marginBottom: 8 }}>
                  <CollectionItemGridResult item={item} />
                </Box>
              </div>
            </Stack>
          </Paper>
        </Grid.Col>
      ))}
    </Grid>
  )
}
