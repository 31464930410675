import { useNavigate } from 'react-router-dom'
import { ROUTES } from '@/utils/constants'
import type { ResultWithId } from '@/utils/types/result-types.ts'
import { useOverviewUrl } from './use-overview-url'

export const useNavigateToResult = () => {
  const navigate = useNavigate()
  return (result: ResultWithId) => {
    navigate(ROUTES.resultsItem.replace(':itemId', result.id))
  }
}

export const useNavigateToResultCallback = () => {
  const navigate = useNavigate()
  return (result: ResultWithId) => () => {
    navigate(ROUTES.resultsItem.replace(':itemId', result.id))
  }
}

export const useNavigateToMap = () => {
  const navigate = useNavigate()
  return () => {
    navigate(ROUTES.map)
  }
}

export const useNavigateToMapItem = () => {
  const navigate = useNavigate()
  return (result: ResultWithId) => {
    navigate(ROUTES.mapItem.replace(':itemId', result.id))
  }
}

export const useNavigateToMapItemCallback = () => {
  const navigate = useNavigate()
  return (result: ResultWithId) => () => {
    navigate(ROUTES.mapItem.replace(':itemId', result.id))
  }
}

export const useNavigateToSearchResults = () => {
  const navigate = useNavigate()
  const overviewUrl = useOverviewUrl()
  return () => {
    navigate(overviewUrl)
  }
}

export const useNavigateToCollection = () => {
  const navigate = useNavigate()
  return (collectionId: string) => {
    navigate(ROUTES.collectionDetail.replace(':collectionId', collectionId))
  }
}

export const useNavigateToCollectionItem = () => {
  const navigate = useNavigate()
  return (collectionId: string, id: string) => {
    navigate(
      ROUTES.collectionItemDetail
        .replace(':itemId', id)
        .replace(':collectionId', collectionId),
    )
  }
}

export const useBackNavigation = () => {
  const navigate = useNavigate()
  return () => {
    navigate(-1)
  }
}
