/**
 * Set of hostnames that require authentication using the provider proxy.
 */
export const PROXY_REQUIRED = new Set<string>([
  'tiles.planet.com',
  'api.gic.org',
  'eapi.maxar.com',
  'e1so.eapi.maxar.com',
  'api.content.maxar.com',
  'nudl-tst.xc.nga.mil',
  'api.nearspacelabs.net',
  'api.dev.danti.ai',
  'api.prod.danti.ai',
])

/**
 * Lookup map from result provider names to provider proxy path prefixes.
 */
export const SOURCE_MAP = new Map<string, string>([
  ['Planet Labs', 'planet'],
  ['VEXCEL', 'vexcel'],
  ['MAXAR', 'maxar'],
  ['NUDL', 'nudl'],
  ['Danti Analytic', 'analytic'],
  ['NEARSPACE', 'nearspace'],
  ['G-EGD', 'gegd'],
  ['SENTINEL_HUB', 'sentinelhub'],
  ['CAPELLA', 'capella'],
  ['SATELLOGIC', 'satellogic'],
  ['UMBRA', 'umbra'],
  ['UP42', 'up42'],
])

/**
 * Regular expression to extract the mime-type from a Content-Type header.
 */
export const MIME = new RegExp(/^\s*([^\s;]*)/)

export const PNG_PROVIDERS = new Set<string>([
  'provider/nudl',
  'provider/maxar',
  'provider/gegd',
  'provider/sentinelhub',
  'provider/vexcel',
  'provider/capella',
  'provider/satellogic',
  'provider/umbra',
])
