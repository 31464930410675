import { parsePageNameFromPath } from '@/features/AppRouting/page-names'
import type { RESULT_CATEGORIES } from '@/utils/constants'
import type { CategoryTypes } from '@/utils/types/raw-result-types'
import type { User } from 'oidc-client-ts'
import { posthog } from 'posthog-js'

export function posthogIdentify(user: User) {
  posthog.identify(user.profile.sub, {
    email: user.profile.email,
    name: user.profile.name,
  })
}

type EventNameFunction = (...args: any) => string
type ResultCategory = keyof typeof RESULT_CATEGORIES
type ComplexEventBase = {
  eventName: string | EventNameFunction
  variables?: string[]
  properties?: string[]
}
type ComplexEventWithProperties = ComplexEventBase & { properties: string[] }
type ComplexEventWithFunction = ComplexEventWithProperties & {
  eventName: EventNameFunction
  variables: string[]
}
type ComplexEvent = ComplexEventWithFunction | ComplexEventWithProperties
type EventProperties = Record<string, string | number | boolean | null>

type PostHogEvent = Record<string, string | ComplexEvent>

export const POSTHOG_EVENTS: Record<string, PostHogEvent> = {
  LOGIN: {
    CLICK: 'Clicked "Log in"',
    FORGOT_PASSWORD: 'Clicked "Forgot Password"',
    SUBMIT_OTP: 'Submitted One-Time-Code',
    LOGOUT: 'Logged out', // ✅
  },
  FEEDBACK: {
    INSIGHT_OPENED: 'Opened Insight Feedback',
    INSIGHT_CLOSED: 'Closed Insight Feedback',
    INSIGHT_SUBMITTED: {
      eventName: 'Submitted Insight Feedback',
      variables: ['id', 'queryId', 'feedback', 'customFeedback'],
      properties: ['id', 'queryId', 'feedback', 'customFeedback'],
    },
    SUBMITTED: 'Submitted Feedback',
  }, // ✅
  HEADER: {
    CLICKED: {
      eventName: (headerItem: string) => `Clicked "${headerItem}" in Header`,
      variables: ['headerItem'],
      properties: ['headerItem'],
    },
  }, // ✅
  USER_MENU: {
    CLICKED: {
      eventName: (menuItem: string) => `Clicked "${menuItem}" in User Menu`,
      variables: ['menuItem'],
      properties: ['menuItem'],
    },
  }, // ✅
  EXPLORE: {
    ALL_OF_TYPE: {
      eventName: (category: CategoryTypes) => `Clicked "All ${category}"`,
      variables: ['category'],
      properties: ['category'],
    },
    EXPAND_MAP: 'Clicked "Expand map"',
    SORT_RELEVANCE: 'Sorted by "Relevance"',
    SORT_DATE: 'Sorted by "Date"',
    FILTER_BY: {
      eventName: (filterName: string) => `Filtered by "${filterName}"`,
      variables: ['filterName'],
      properties: ['filterName', 'value', 'isOn'],
    },
    ADVANCED_SEARCH: {
      eventName: 'Triggered "Advanced Search"',
      properties: ['filters'],
    },
    SAVE_SEARCH: 'Clicked "Save Search"',
  }, // ✅
  RESULTS: {
    CLICKED: {
      eventName: (resultCategory: ResultCategory) =>
        `Clicked "${resultCategory}" result`,
      variables: ['resultCategory'],
      properties: ['resultCategory'],
    },
  }, // ✅
  RESULT_MENU: {
    CLICKED: {
      eventName: (resultCategory: ResultCategory, actionLabel: string) =>
        `Clicked "${resultCategory}" result menu: "${actionLabel}"`,
      variables: ['resultCategory', 'actionLabel'],
      properties: ['resultCategory', 'actionLabel'],
    },
  }, // ✅
  DETAIL: {
    NAV_NEXT: 'Navigated to next item',
    NAV_PREV: 'Navigated to previous item',
    ADD_TO_COLLECTION: {
      eventName: (resultCategory: ResultCategory) =>
        `Added "${resultCategory}" result to Collection`,
      variables: ['resultCategory'],
      properties: ['resultCategory'],
    },

    DOWNLOAD: {
      eventName: (resultCategory: ResultCategory) =>
        `Started Download of "${resultCategory}" result`,
      variables: ['resultCategory'],
      properties: ['resultCategory'],
    },
    ANALYZE: {
      eventName: (resultCategory: ResultCategory) =>
        `Started Analysis of "${resultCategory}" result`,
      variables: ['resultCategory'],
      properties: ['resultCategory'],
    },
  }, // ✅
  COLLECTIONS: {
    ADD: 'New Collection created',
    SEARCH: {
      eventName: (searchQuery: string) =>
        `Searched Collections for "${searchQuery}"`,
      variables: ['searchQuery'],
      properties: ['searchQuery'],
    },
    SHARE: {
      eventName: (collectionName: string) =>
        `Shared Collection "${collectionName}"`,
      variables: ['collectionName'],
      properties: ['collectionName', 'collectionId', 'recipient'],
    },
    FILTER: {
      eventName: (filterName: string) =>
        `Filtered Collections by "${filterName}"`,
      variables: ['filterName'],
      properties: ['filterName', 'filterValue'],
    },
    EDIT_CONFIG: {
      eventName: (collectionName: string) =>
        `Opened config for Collection "${collectionName}"`,
      variables: ['collectionName'],
      properties: ['collectionName'],
    },
    DELETE: {
      eventName: (collectionName: string) =>
        `Deleted Collection "${collectionName}"`,
      variables: ['collectionName'],
      properties: ['collectionName'],
    },
  }, // ✅
  HISTORY: {
    SEARCH: 'Searched "History"',
    SELECT: 'Selected "History" item',
    REMOVE: 'Removed "History" item',
    REMOVE_ALL: 'Removed all "History" items',
    RERUN_SEARCH: 'Reran "History" search',
  }, // ✅
  TIMELINE: {
    CLICKED: 'Clicked "Timeline"',
  },
}

// This provides some element of type safety for the definition of the function
//  but not its usage, for some reason.
type PostHogEvents = typeof POSTHOG_EVENTS
type PostHogEventCategory = keyof PostHogEvents
type PostHogEventCategoryItems = PostHogEvents[PostHogEventCategory]
type PostHogEventObject =
  PostHogEventCategoryItems[keyof PostHogEventCategoryItems]

function isComplexEvent(event: PostHogEventObject): event is ComplexEvent {
  return (
    typeof event === 'object' &&
    'eventName' in event &&
    Array.isArray(event.properties)
  )
}
function isDynamicEventName(
  event: PostHogEventObject,
): event is ComplexEventWithFunction {
  return (
    isComplexEvent(event) &&
    typeof event.eventName === 'function' &&
    Array.isArray(event.variables)
  )
}
function isStaticEventName(
  event: PostHogEventObject,
): event is ComplexEventWithProperties {
  return isComplexEvent(event) && typeof event.eventName === 'string'
}

function hasAllProps(properties: EventProperties, requiredProps: string[]) {
  return requiredProps.every((prop) => prop in properties)
}
function logEventPropertyError(eventName: string, properties: string[]) {
  console.error(
    `The following properties are required for the '${eventName}' event:`,
    properties.join(', '),
  )
}

// This gets messy because the event names can be dynamic
export function captureEvent(
  event: PostHogEventObject,
  properties?: EventProperties,
) {
  const pageName = parsePageNameFromPath()
  const propsWithPage = { pageName, ...properties }

  if (isComplexEvent(event)) {
    if (isDynamicEventName(event)) {
      if (properties && hasAllProps(properties, event.properties)) {
        // Extract argument values from provided properties
        const args = event.variables.map((variable) => properties[variable])
        // Pass args to event name function
        const eventName = event.eventName(...args)
        return posthog.capture(eventName, propsWithPage)
      } else {
        // Error when properties are missing:
        const eventName = event.eventName(...event.variables)
        return logEventPropertyError(eventName, event.properties)
      }
    } else if (isStaticEventName(event)) {
      const eventName = event.eventName as string // Narrowing wasn't enough?
      return properties && hasAllProps(properties, event.properties)
        ? posthog.capture(eventName, propsWithPage)
        : logEventPropertyError(eventName, event.properties)
    }
  } else {
    // Otherwise, the `event` is a plain string
    return posthog.capture(event, propsWithPage)
  }
}
