import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'

dayjs.extend(relativeTime)

export const formatDatetime = (datetime: string): string =>
  dayjs(datetime).format('MM/DD/YYYY [at] hh:mm A')

export const formatDate = (datetime: string): string =>
  dayjs(datetime).format('YYYY-MM-DD')

export const formatTime = (datetime: string): string =>
  dayjs(datetime).format('HH:mm')

export const fromNow = (datetime: string): string => dayjs(datetime).fromNow()

export const formatDatetimeWithWordMonths = (datetime: string): string =>
  dayjs(datetime).format('MMMM DD, HH:mm')

export const isValidDatetime = (datetime: string): boolean =>
  dayjs(datetime).isValid()

export const hourlyFrequency = (hour: number) =>
  `DAILY(${dayjs().hour(hour).minute(0).format('HH:mm')})`
