import { useImageResults } from '@/api/results'
import { usePostfilterImageResults } from '@/stores/results-filter-store'
import type { PostFilterType } from '@/stores/results-filter-store/types'
import {
  extractAssetName,
  extractCompanyName,
  extractSensorName,
} from '@/stores/results-filter-store/utils'
import type { ImageryResult } from '@/utils/types/result-types'
import { type Dictionary, groupBy } from 'lodash'

export const makeGroupHistogram = (
  results: ImageryResult[],
  filterType: PostFilterType,
): Dictionary<ImageryResult[]> => {
  switch (filterType) {
    case 'source':
      return groupBy(results, (ir) => extractCompanyName(ir))
    case 'repo':
      return groupBy(results, (ir) => ir.source)
    case 'asset':
      return groupBy(results, (ir) => extractAssetName(ir))
    case 'sensor':
      return groupBy(results, (ir) => extractSensorName(ir))
  }
  return {}
}

export const useImageResultsPropertyHistogram = () => {
  const allImageResults = useImageResults()

  const prefilteredResults: Partial<{
    [key in PostFilterType]: ImageryResult[]
  }> = {
    source: usePostfilterImageResults({ excludeType: 'source' }),
    repo: usePostfilterImageResults({ excludeType: 'repo' }),
    asset: usePostfilterImageResults({ excludeType: 'asset' }),
    sensor: usePostfilterImageResults({ excludeType: 'sensor' }),
  }

  const makeHistogramWithZeroElements = (
    filterType: PostFilterType,
  ): Dictionary<ImageryResult[]> => {
    const fullHistogram = makeGroupHistogram(allImageResults, filterType)
    const prefilteredResultsForType = prefilteredResults[filterType]
    if (!prefilteredResultsForType) {
      return fullHistogram || {}
    }
    const filteredHistogram = makeGroupHistogram(
      prefilteredResultsForType,
      filterType,
    )
    const zeroEntries = Object.fromEntries(
      Object.keys(fullHistogram)
        .filter((k) => !filteredHistogram[k])
        .map((k) => [k, []]),
    )

    return {
      ...filteredHistogram,
      ...zeroEntries,
    }
  }

  return {
    source: makeHistogramWithZeroElements('source'),
    repo: makeHistogramWithZeroElements('repo'),
    asset: makeHistogramWithZeroElements('asset'),
    sensor: makeHistogramWithZeroElements('sensor'),
  }
}
