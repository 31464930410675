import { downloadFile } from '@/api/collections/collection-items'
import type { CollectionItem } from '@/api/collections/types'
import {
  openAddToCollectionModal,
  openDownloadItemModal,
  openRemoveFromCollectionModal,
} from '@/components/modals/openers.tsx'
import {
  useNavigateToCollectionItem,
  useNavigateToMapItem,
  useNavigateToResult,
} from '@/features/AppRouting/hooks'
import { makeOrthoLayer } from '@/features/DantiMap/utils'
import { warnExternalLink } from '@/features/ExternalLink'
import { MENU_OPTION_KEYS } from '@/features/ResultActions/constants'
import type { ClickEventHandler } from '@/features/ResultActions/types'
import {
  useClearOrthoLayerGroupIds,
  useToggleOrthoLayerGroupId,
} from '@/stores/map-store'
import { ROUTES } from '@/utils/constants'
import { captureEvent, POSTHOG_EVENTS } from '@/utils/posthog'
import {
  isFileResult,
  isNewsResult,
  isSocialMediaResult,
} from '@/utils/types/result-type-checkers'
import { isImageryResult, type ResultWithId } from '@/utils/types/result-types'
import { captureException } from '@sentry/react'

export const useMenuOptionActions = ({
  item,
  result,
}: {
  item?: CollectionItem
  result: ResultWithId
}): { [key: string]: ClickEventHandler } => {
  const createActionClickHandler =
    (action: (clickedResult: ResultWithId) => void, label: string) =>
    (event: React.MouseEvent) => {
      event.stopPropagation()
      captureEvent(POSTHOG_EVENTS.RESULT_MENU.CLICKED, {
        actionName: label,
        category: result.properties.resultCategory as string,
      })
      action(result)
    }
  const navigateToResult = useNavigateToResult()
  const navigateToMapItem = useNavigateToMapItem()
  const navigateToCollectionItem = useNavigateToCollectionItem()
  const clearOrthoLayerGroupIds = useClearOrthoLayerGroupIds()
  const toggleOrthoLayerGroupId = useToggleOrthoLayerGroupId()

  const canDownload = item && isFileResult(result) && !item.name

  const clearLayerAndNavigate = (
    clickedResult: ResultWithId,
    navigate: (result: ResultWithId) => void,
  ) => {
    if (isImageryResult(clickedResult)) {
      const layer = makeOrthoLayer(clickedResult)
      if (layer) {
        clearOrthoLayerGroupIds()
        toggleOrthoLayerGroupId(layer.groupKey)
        navigate(clickedResult)
      } else {
        captureException(
          `Could not correlate result id ${clickedResult.id} to existing ortholayer`,
        )
      }
    } else {
      navigate(clickedResult)
    }
  }
  return {
    [MENU_OPTION_KEYS.addToCollection]: createActionClickHandler(
      openAddToCollectionModal,
      MENU_OPTION_KEYS.addToCollection,
    ),
    [MENU_OPTION_KEYS.visitArticle]: createActionClickHandler(
      (result: ResultWithId) => {
        warnExternalLink(result.properties.link)
      },
      MENU_OPTION_KEYS.visitArticle,
    ),
    [MENU_OPTION_KEYS.downloadImage]: createActionClickHandler(
      (result: ResultWithId) => {
        if (isImageryResult(result)) {
          openDownloadItemModal(result)
        }
      },
      MENU_OPTION_KEYS.downloadImage,
    ),
    [MENU_OPTION_KEYS.viewOnMap]: createActionClickHandler(
      (clickedResult: ResultWithId) => {
        clearLayerAndNavigate(clickedResult, navigateToMapItem)
      },
      MENU_OPTION_KEYS.viewOnMap,
    ),
    [MENU_OPTION_KEYS.viewDetails]: createActionClickHandler(
      (clickedResult: ResultWithId) => {
        if (
          item &&
          !(
            isFileResult(clickedResult) ||
            isNewsResult(clickedResult) ||
            isSocialMediaResult(clickedResult)
          )
        ) {
          navigateToCollectionItem(item.collectionId, item.id)
        } else {
          clearLayerAndNavigate(clickedResult, navigateToResult)
        }
      },
      MENU_OPTION_KEYS.viewDetails,
    ),
    ...(item && {
      [MENU_OPTION_KEYS.removeFromCollection]: createActionClickHandler(
        () => openRemoveFromCollectionModal(item.collectionId, item.id),
        MENU_OPTION_KEYS.removeFromCollection,
      ),
    }),
    ...(canDownload && {
      downloadFile: () => {
        void downloadFile(
          ROUTES.collectionItemDownload
            .replace(':collectionId', item.collectionId)
            .replace(':itemId', item.id),
          item.name,
        )
      },
    }),
  }
}
