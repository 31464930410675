import { Table } from '@mantine/core'
import type { CollectionSortType } from '@/api/collections'
import type { CollectionItem } from '@/api/collections/types'
import { openResultDetailsModal } from '@/components/modals/openers'
import { useNavigateToCollectionItem } from '@/features/AppRouting/hooks'
import { warnExternalLink } from '@/features/ExternalLink'
import {
  CategoryTypes,
  type ResultWithId,
} from '@/utils/types/raw-result-types'
import {
  isFireResult,
  isImageryResult,
  isShipResult,
} from '@/utils/types/result-types'
import {
  type ColumnFiltersState,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table'
import { CollectionDetailTableColumns } from './CollectionDetailTableColumns'

export interface CollectionDetailTableProps {
  items: CollectionItem[]
  onClick: (id: string) => void
  setSort: (sort: CollectionSortType) => void
  onCategoryToggle: (categoryTypes: CategoryTypes) => void
  columnFilters?: ColumnFiltersState | undefined
}

export function CollectionDetailTable({
  items,
  setSort,
  onCategoryToggle,
  columnFilters,
}: CollectionDetailTableProps) {
  const table = useReactTable({
    data: items,
    columns: CollectionDetailTableColumns,
    getCoreRowModel: getCoreRowModel(),
    getRowId: (collection) => collection.id,
    state: { columnFilters },
    meta: {
      setSort: (sort: CollectionSortType) => {
        setSort(sort)
      },
      onCategoryToggle: (categoryTypes: CategoryTypes) => {
        onCategoryToggle(categoryTypes)
      },
    },
  })

  const navigateToCollectionItem = useNavigateToCollectionItem()

  const handleOnClick = (item: CollectionItem) => {
    const result = JSON.parse(item.doc) as ResultWithId
    if (isFireResult(result) || isShipResult(result)) {
      return openResultDetailsModal(result)
    }
    if (isImageryResult(result)) {
      return navigateToCollectionItem(item.collectionId, item.id)
    }
    warnExternalLink(result.properties.link)
  }

  return (
    <Table highlightOnHover visibleFrom="sm">
      <Table.Thead>
        {table.getHeaderGroups().map((headerGroup) => (
          <Table.Tr key={headerGroup.id}>
            {headerGroup.headers.map((h) => (
              <Table.Th key={h.id} w={h.getSize()} pt={8} pb={8}>
                {h.isPlaceholder
                  ? null
                  : flexRender(h.column.columnDef.header, h.getContext())}
              </Table.Th>
            ))}
          </Table.Tr>
        ))}
      </Table.Thead>
      <Table.Tbody>
        {table.getRowModel().rows.map((row) => {
          const isFileResult = row.original.category === CategoryTypes.FILE
          const onClick = isFileResult
            ? undefined
            : () => handleOnClick(items[row.index])
          const style = isFileResult ? { cursor: 'pointer' } : {}
          return (
            <Table.Tr style={style} key={row.id} onClick={onClick}>
              {row.getVisibleCells().map((h) => (
                <Table.Td key={h.id}>
                  {flexRender(h.column.columnDef.cell, h.getContext())}
                </Table.Td>
              ))}
            </Table.Tr>
          )
        })}
      </Table.Tbody>
    </Table>
  )
}
