import * as React from 'react'
import { getHighestRisks } from '@/api/results'
import type { HazardRiskResult as HazardRiskResultType } from '@/utils/types/result-types.ts'

export const useHighestRiskType = (result: HazardRiskResultType) => {
  const topRisks = getHighestRisks(result)
  const topRiskKey = React.useMemo(() => {
    return topRisks[Math.round(Math.random() * (topRisks.length - 1))]
  }, [topRisks])
  return topRiskKey
}

export default useHighestRiskType
