import {
  DATA_CATEGORIES,
  DATA_SOURCES,
  RESULT_CATEGORIES,
} from '@/utils/constants'
import type {
  AnalyticResult,
  DataResult,
  ImageryResult,
  PropertyResult,
  PublicationResult,
  RawSearchResult,
  RelatedImageryResult,
  ResultWithId,
} from './raw-result-types'
import type {
  AttomResult,
  FileResult,
  FireResult,
  HazardPropertyResult,
  HazardRiskResult,
  InsightResult,
  NewsResult,
  PortResult,
  RegridResult,
  ShipResult,
  SocialMediaResult,
  VesselResult,
} from './result-types'

export function isPropertyResult(
  result: ResultWithId,
): result is PropertyResult {
  return result.category === DATA_CATEGORIES.PROPERTY
}
export function isDataResult(result: RawSearchResult): result is DataResult {
  return result.category === DATA_CATEGORIES.DATA
}

export function isUp42(source: string) {
  return source === DATA_SOURCES.UP42
}

export function isUp42Result(result: ResultWithId) {
  return isUp42(result.source)
}

export const isSentinel = (source: string) => source === DATA_SOURCES.SENTINEL
export function isSentinelResult(result: ResultWithId) {
  return isSentinel(result.source)
}

export function isVexcel(result: ImageryResult) {
  return result.properties._dSource === DATA_SOURCES.VEXCEL
}

export function isBlackSky(source: string) {
  return source === DATA_SOURCES.BLACKSKY
}

export function isSatellogic(source: string) {
  return source === DATA_SOURCES.SATELLOGIC
}

export function isUmbra(source: string) {
  return source === DATA_SOURCES.UMBRA
}

export function isCapella(source: string) {
  return source === DATA_SOURCES.CAPELLA
}

export function isBlackSkyResult(result: ResultWithId) {
  return isBlackSky(result.source)
}

export function isSocialMediaResult(
  result: ResultWithId,
): result is SocialMediaResult {
  return result.category === DATA_CATEGORIES.SOCIAL_MEDIA
}

export function isFileResult(result: ResultWithId): result is FileResult {
  return result.category === RESULT_CATEGORIES.FILE
}

export function isFireResult(result: ResultWithId): result is FireResult {
  return ['FIRMS'].includes(result.properties._dProvider)
}

export function isNewsResult(result: ResultWithId): result is NewsResult {
  return result.category === RESULT_CATEGORIES.WEB_ARTICLE
}

export function isVesselResult(result: ResultWithId): result is VesselResult {
  return (
    ['SPIRE'].includes(result.properties._dProvider) &&
    result.properties.recordType === 'vessel'
  )
}

export function isPortResult(result: ResultWithId): result is PortResult {
  return (
    ['SPIRE'].includes(result.properties._dProvider) &&
    result.properties.recordType === 'portEvent'
  )
}
export function isShipResult(result: ResultWithId): result is ShipResult {
  return isVesselResult(result) || isPortResult(result)
}

export function isAttomResult(result: ResultWithId): result is AttomResult {
  return ['ATTOM'].includes(result.properties._dProvider)
}

export function isHazardPropertyResult(
  result: ResultWithId,
): result is HazardPropertyResult {
  return result.properties.resultCategor === RESULT_CATEGORIES.HAZARD_PROPERTY
}

export function isHazardRiskResult(
  result: ResultWithId,
): result is HazardRiskResult {
  return (
    ['HAZARD'].includes(result.properties._dProvider) &&
    result.properties.property === undefined
  )
}
export function isRegridResult(result: ResultWithId): result is RegridResult {
  return ['REGRID'].includes(result.properties._dProvider)
}

export const isRecognizedPropertyResult = (
  result: ResultWithId,
): result is PropertyResult =>
  isPropertyResult(result) ||
  isHazardPropertyResult(result) ||
  isRegridResult(result) ||
  isAttomResult(result)

export function isImageryResult(result: ResultWithId): result is ImageryResult {
  return [
    DATA_CATEGORIES.IMAGE,
    DATA_CATEGORIES.RELATED_IMAGERY,
    DATA_CATEGORIES.ANALYTIC,
  ].includes(result.category)
}
export function isAnalyticResult(
  result: ResultWithId,
): result is AnalyticResult {
  return [DATA_CATEGORIES.ANALYTIC].includes(result.category)
}

export function isRelatedImageryResult(
  result: ResultWithId,
): result is RelatedImageryResult {
  return result.category === DATA_CATEGORIES.RELATED_IMAGERY
}

export function isInsightResult(result: ResultWithId): result is InsightResult {
  return result.category === DATA_CATEGORIES.INSIGHT
}

export function isPublicationResult(
  result: ResultWithId,
): result is PublicationResult {
  return result.category === DATA_CATEGORIES.PUBLICATION
}
