import { useLocation } from 'react-router-dom'
import { SearchBar } from '@/features/Search'
import { useCurrentQuery } from '@/stores/queries-store'
import type { PropsWithChildren } from 'react'

import styles from './footer.module.css'

export function Footer({ children }: PropsWithChildren) {
  const { pathname } = useLocation()
  const isExplore = pathname === '/'
  const isLogIn = pathname === '/log-in'
  const currentQuery = useCurrentQuery()
  if (isExplore || isLogIn) {
    return null
  }

  return (
    <>
      {children}
      <footer className={styles.footer}>
        <SearchBar
          className={styles.searchBar}
          value={currentQuery}
          placeholder={
            currentQuery.trim() ||
            'Search for a place or event and a city/country, lat/long or MGRS.'
          }
        />
      </footer>
    </>
  )
}
