import { isVesselResult } from '@/utils/types/result-type-checkers.ts'
import type { ShipResult } from '@/utils/types/result-types.ts'

export const getShipName = (result: ShipResult) =>
  (isVesselResult(result)
    ? result.properties.staticData.name
    : result.properties.vessel.staticData.name) ?? 'Unknown Vessel'

export const getShipLocation = (result: ShipResult) =>
  isVesselResult(result)
    ? result.properties.lastPositionUpdate
    : result.properties.location.centerPoint

export const getShipDetails = (result: ShipResult) => {
  if (isVesselResult(result)) {
    const { name, imo, mmsi, shipType, flag, callsign } =
      result.properties.staticData
    const {
      latitude,
      longitude,
      navigationalStatus,
      updateTimestamp: lastUpdateTimestamp,
    } = result.properties.lastPositionUpdate
    const { destination, draught, eta } = result.properties.currentVoyage || {}
    return {
      name: name || 'Unknown Vessel',
      imo,
      mmsi,
      lastUpdateTimestamp,
      latitude,
      longitude,
      navigationalStatus: navigationalStatus
        ? navigationalStatus.toLowerCase()
        : null,
      destination,
      draught,
      eta,
      shipType: shipType ? shipType.toLowerCase() : null,
      flag,
      callSign: callsign,
    }
  }
  return {}
}
