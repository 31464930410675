import type { CardProps } from '@mantine/core'
import { BentoCard } from '@/features/BentoResults/components'
import { useImageCardEvents } from '@/features/BentoResults/hooks/use-image-card-events'
import { HIDE_ACTIONS_BY_CATEGORY } from '@/features/ResultActions/constants'
import { ICONS, RESULT_CATEGORIES } from '@/utils/constants'
import { formatDate, formatTime } from '@/utils/format-datetime'
import type { CollectionItem } from '@/api/collections/types'
import type { ImageryResult } from '@/utils/types/result-types'

const category = RESULT_CATEGORIES.IMAGE
const actionsToHide = HIDE_ACTIONS_BY_CATEGORY[category]

export interface ImageBentoCardProps extends CardProps {
  item?: CollectionItem
  result: ImageryResult
  totalResults: number
  size: string
}

export const ImageBentoCard = ({ result, ...props }: ImageBentoCardProps) => {
  const { onMouseEnter, onMouseLeave } = useImageCardEvents(result)
  const { datetime } = result.properties
  const datetimeDay = formatDate(datetime)
  const datetimeTime = formatTime(datetime)
  return (
    <BentoCard
      {...props}
      result={result}
      resultCategory={category}
      hideActions={actionsToHide}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      icon={ICONS.satelliteAlt}
      highlightIcon
      image={{
        thumbnail: result.thumbnail,
        source: result.source,
        title: result.title,
      }}
      content={{
        subHeading: (
          <>
            {datetimeDay}
            <br />
            {datetimeTime}
          </>
        ),
      }}
    />
  )
}
