import React from 'react'
import { useNavigate } from 'react-router-dom'
import { Card, type CardProps, Group, Text } from '@mantine/core'
import type { CollectionItem } from '@/api/collections/types'
import {
  type ActionMenuOption,
  actionMenuOptions,
  type ActionMenuType,
  isActionMenuOption,
} from '@/components/lib/results/Result/action-menu-options'
import { openResultDetailsModal } from '@/components/modals/openers'
import { makeOrthoLayer } from '@/features/DantiMap/utils'
import { useIsDownloadable } from '@/hooks/results/use-is-downloadable'
import { useFeatureFlag } from '@/hooks/use-feature-flag'
import { useShowScores } from '@/stores/config-store'
import {
  useClearOrthoLayerGroupIds,
  useToggleOrthoLayerGroupId,
} from '@/stores/map-store'
import { FEATURE_FLAG_VALUES } from '@/utils/constants'
import { captureEvent, POSTHOG_EVENTS } from '@/utils/posthog'
import { isFileResult } from '@/utils/types/result-type-checkers'
import { isImageryResult, type ResultWithId } from '@/utils/types/result-types'
import { classes } from '@/utils/ui-helpers'
import { captureException } from '@sentry/react'
import { ResultMenu } from './ResultMenu'

import styles from './result-base.module.css'

interface ResultBaseProps extends CardProps {
  item?: CollectionItem
  result: ResultWithId
  children?: React.ReactNode
  onClick?: ActionMenuOption | (() => void)
  onMouseEnter?: () => void
  onMouseLeave?: () => void
  className?: string
  disableNavigate?: boolean
}

export const ResultBase = React.forwardRef(
  (
    {
      result,
      children,
      onClick,
      onMouseEnter,
      onMouseLeave,
      className,
      item,
      disableNavigate,
      ...props
    }: ResultBaseProps,
    forwardedRef: React.ForwardedRef<HTMLDivElement>,
  ) => {
    const navigate = useNavigate()
    // Do not activate bento-specific behaviors on collection item results
    const toggleOrthoLayerGroupId = useToggleOrthoLayerGroupId()
    const clearOrthoLayerGroupIds = useClearOrthoLayerGroupIds()
    const { data: isDownloadable } = useIsDownloadable(result)
    const isShowScoresFeatureEnabled = useFeatureFlag(
      FEATURE_FLAG_VALUES.showScores,
    )
    const showScores = useShowScores() && isShowScoresFeatureEnabled

    const adjustedActionMenuOptions: Record<string, ActionMenuType> = {
      ...actionMenuOptions,
      downloadImage: {
        ...actionMenuOptions['downloadImage'],
        disabled: !isDownloadable,
        title: isDownloadable ? undefined : 'Not available for download',
      },
      viewOnMap: {
        ...actionMenuOptions['viewOnMap'],
        onClick: (clickedResult: ResultWithId) => {
          if (isImageryResult(clickedResult)) {
            const layer = makeOrthoLayer(clickedResult)
            if (layer) {
              clearOrthoLayerGroupIds()
              toggleOrthoLayerGroupId(layer.groupKey)
              navigate(`/map/${clickedResult.id}`)
            } else {
              captureException(
                `Could not correlate result id ${clickedResult.id} to existing ortholayer`,
              )
            }
          }
        },
      },
      viewDetails: {
        ...actionMenuOptions['viewDetails'],
        onClick: (clickedResult: ResultWithId) => {
          if (item && !disableNavigate) {
            navigate(`/collections/${item.collectionId}/items/${item.id}`)
          } else if (item) {
            navigate(`/results/${clickedResult.id}`)
          } else if (isImageryResult(clickedResult)) {
            const layer = makeOrthoLayer(clickedResult)
            if (layer) {
              clearOrthoLayerGroupIds()
              toggleOrthoLayerGroupId(layer.groupKey)
              navigate(`/results/${clickedResult.id}`)
            } else {
              captureException(
                `Could not correlate result id ${clickedResult.id} to existing ortholayer`,
              )
            }
          } else {
            openResultDetailsModal(clickedResult)
          }
        },
      },
    }

    const isAnImageResult = isImageryResult(result) || isFileResult(result)

    // override onClick to point to viewDetails for collection items
    const cardClick = item ? 'onClick' : 'viewDetails'
    const onClickAction = isActionMenuOption(cardClick)
      ? adjustedActionMenuOptions[cardClick].onClick
      : cardClick

    return (
      <Card
        ref={forwardedRef}
        onClick={() => {
          captureEvent(POSTHOG_EVENTS.RESULTS.CLICKED, {
            resultCategory: result.properties.resultCategory ?? result.category,
          })
          onClickAction?.(result)
        }}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        className={classes(styles['hidden-panel'], className)}
        radius={0}
        pos="relative"
        p={0}
        pb={isAnImageResult && showScores ? 'sm' : 0}
        fz="sm"
        c="gray.5"
        {...props}
      >
        {children}
        {showScores && (
          <Group
            pos="absolute"
            bottom={0}
            left={isAnImageResult ? 0 : 'var(--mantine-spacing-sm)'}
          >
            <Text size="xs" fw={700}>
              score: {result.score}
            </Text>
          </Group>
        )}
        <Group className={styles['reveal']} pos="absolute" inset={0}>
          <Group pos="relative" h="100%" w="100%">
            <ResultMenu
              item={item}
              result={result}
              disableNavigate={disableNavigate}
            />
          </Group>
        </Group>
      </Card>
    )
  },
)

ResultBase.displayName = 'ResultBase'
