import React from 'react'
import { useNavigate } from 'react-router-dom'
import { Container, Stack } from '@mantine/core'
import { type HistoryItem, useHistory } from '@/api/history'
import {
  type ProviderSource,
  useProviders,
} from '@/components/filters/providers/use-providers'
import { openSaveSearchModal } from '@/components/modals/SaveSearchModal/opener'
import { useSearch } from '@/features/Search/hooks'
import { SAVED_SEARCH_MODES } from '@/utils/constants'
import { captureEvent, POSTHOG_EVENTS } from '@/utils/posthog'
import { format, isToday, isYesterday } from 'date-fns'
import {
  HistoryDateSection,
  type HistorySectionData,
} from './HistoryDateSection'
import { HistoryDetailHeader } from './HistoryDetailHeader'
import { HistoryEmptySection } from './HistoryEmptySection'
import { HistorySelectedBar } from './HistorySelectedBar'

const getFormattedDateTitle = (d: Date) => {
  const date = new Date(d)
  const formattedDate = format(date, 'EEEE, MMMM d, yyyy')

  if (isToday(date)) {
    return `Today, ${formattedDate}`
  } else if (isYesterday(date)) {
    return `Yesterday, ${formattedDate}`
  } else {
    return formattedDate
  }
}

const handleItemMonitorLocation = (
  { query }: HistoryItem,
  providers: ProviderSource[],
) => {
  openSaveSearchModal({
    isNew: true,
    providers,
    params: {
      query,
      name: query,
      emailNotification: '',
      mode: SAVED_SEARCH_MODES.feed,
      locations: [],
    },
  })
}

export function HistoryDetail() {
  const { pagedItems, currentPage, isLoading, deleteHistory, searchTerm } =
    useHistory()
  const navigate = useNavigate()
  const { doTextSearch } = useSearch()

  const [selectedItemIds, setSelectedItemIds] = React.useState<string[]>([])
  const { newProviders } = useProviders()

  const itemsByDate = React.useMemo(() => {
    const map = new Map<string, HistorySectionData>()
    pagedItems
      .filter(
        (x) => searchTerm.trim() === '' || x.query.includes(searchTerm.trim()),
      )
      .forEach((x) => {
        const date = new Date(x.queryTime)
        const formattedDate = getFormattedDateTitle(date)

        if (map.get(formattedDate)) {
          map.get(formattedDate)?.items.push(x)
        } else {
          map.set(formattedDate, {
            date,
            items: [x],
          })
        }
      })

    return map
  }, [pagedItems, searchTerm])

  const dates = React.useMemo(() => {
    return [...itemsByDate.keys()]
  }, [itemsByDate])

  React.useEffect(() => {
    setSelectedItemIds([])
  }, [currentPage])

  const handleItemToggle = (itemId: string) => {
    if (selectedItemIds.includes(itemId)) {
      setSelectedItemIds((ids) => ids.filter((x) => x != itemId))
    } else {
      captureEvent(POSTHOG_EVENTS.HISTORY.SELECT)
      setSelectedItemIds((ids) => [...ids, itemId])
    }
  }

  const handleRemoveSelected = () => {
    captureEvent(POSTHOG_EVENTS.HISTORY.REMOVE)
    deleteHistory({ ids: selectedItemIds })
    setSelectedItemIds([])
  }

  const handleItemRemove = (itemId: string) => {
    captureEvent(POSTHOG_EVENTS.HISTORY.REMOVE)
    deleteHistory({ ids: [itemId] })
    setSelectedItemIds((itemIds) => itemIds.filter((x) => x != itemId))
  }

  const handleItemClick = (item: HistoryItem) => {
    captureEvent(POSTHOG_EVENTS.HISTORY.RERUN_SEARCH)

    void doTextSearch(item.query)

    const params = new URLSearchParams({ search: item.query })
    navigate(`/overview?${params.toString()}`)
  }

  const handleRemoveDateRange = (startAt: Date, endAt: Date) => {
    deleteHistory({
      startAt: startAt.toISOString(),
      endAt: endAt.toISOString(),
    })
  }

  return (
    <Container size="900px" p={{ xs: 0 }}>
      <Stack gap="lg">
        <HistoryDetailHeader />
        {selectedItemIds.length > 0 && (
          <HistorySelectedBar
            count={selectedItemIds.length}
            onClear={() => setSelectedItemIds([])}
            onRemove={handleRemoveSelected}
          />
        )}
        {isLoading ? (
          <></>
        ) : dates.length === 0 ? (
          <HistoryEmptySection />
        ) : (
          dates.map((date) => (
            <HistoryDateSection
              key={date}
              date={itemsByDate.get(date)!.date}
              title={date}
              selectedItemIds={selectedItemIds}
              items={itemsByDate.get(date)!.items}
              onToggle={handleItemToggle}
              onRemove={handleItemRemove}
              onRemoveDateRange={handleRemoveDateRange}
              onClick={handleItemClick}
              onMonitorLocation={(item) =>
                handleItemMonitorLocation(item, newProviders)
              }
            />
          ))
        )}
      </Stack>
    </Container>
  )
}
