import { Button } from '@mantine/core'
import type { FC, MouseEventHandler } from 'react'

import styles from '@/features/DantiMap/danti-map.module.css'

type MapSearchHereButtonProps = {
  onClick: MouseEventHandler
}

export const MapSearchHereButton: FC<MapSearchHereButtonProps> = ({
  onClick,
}) => {
  return (
    <Button
      variant="filled"
      onClick={onClick}
      size="md"
      className={styles.mapSearchHereButton}
    >
      Search Here
    </Button>
  )
}
