import { type CardProps, Group, Stack, Text } from '@mantine/core'
import { useIntersection } from '@mantine/hooks'
import type { CollectionItem } from '@/api/collections/types'
import { Icon } from '@/components/lib/Icon'
import { ResultImage } from '@/components/lib/ResultImage'
import { ResultBase } from '@/components/lib/results/Result/ResultBase'
import { makeOrthoLayer } from '@/features/DantiMap/utils'
import { useIsDownloadable } from '@/hooks/results/use-is-downloadable'
import { useSetHoveredLayerId } from '@/stores/map-store'
import { extractCompanyName } from '@/stores/results-filter-store/utils'
import {
  type ImageryResult,
  isAnalyticResult,
} from '@/utils/types/result-types'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'

dayjs.extend(utc)

interface ImageResultProps extends CardProps {
  item?: CollectionItem
  result: ImageryResult
  isFullscreen?: boolean
}

function formatQuality(quality: number) {
  return quality
    ? quality < 1
      ? `${Math.round(quality * 100)}cm`
      : `${Math.round(quality)}m`
    : null
}

export function ImageResult({
  result,
  isFullscreen,
  ...props
}: ImageResultProps) {
  const setHoveredLayerId = useSetHoveredLayerId()

  const layer = makeOrthoLayer(result)

  const { ref, entry } = useIntersection({
    rootMargin: '0px',
    threshold: 0,
  })

  const { data: isDownloadable } = useIsDownloadable(
    result,
    entry?.isIntersecting,
  )

  return (
    <ResultBase
      result={result}
      onClick="viewDetails"
      onMouseEnter={() => setHoveredLayerId(layer?.groupKey ?? null)}
      onMouseLeave={() => setHoveredLayerId(null)}
      data-cy="imageResult"
      {...props}
    >
      <div
        ref={ref}
        style={{
          position: 'relative',
          overflow: 'hidden',
          aspectRatio: `${isFullscreen ? 2.4 : 1.95}`,
          borderRadius: 'var(--mantine-radius-md)',
          backgroundColor: 'var(--mantine-color-gray-2)',
        }}
      >
        {isDownloadable && (
          <Icon
            name="download"
            title="Available for download"
            style={{
              backgroundColor: 'var(--mantine-color-gray-2)',
              borderRadius: 'var(--mantine-radius-sm)',
              color: 'var(--mantine-color-gray-6)',
              position: 'absolute',
              bottom: '0.25rem',
              right: '0.25rem',
              opacity: 0.6,
            }}
          />
        )}
        {isAnalyticResult(result) && (
          <Icon
            name="analytics"
            title="Analytics available"
            style={{
              backgroundColor: 'var(--mantine-color-gray-2)',
              borderRadius: 'var(--mantine-radius-sm)',
              textShadow: '3px 3px 7px #0006',
              color: 'var(--mantine-color-gray-6)',
              position: 'absolute',
              bottom: '0.25rem',
              left: '0.25rem',
              opacity: 0.9,
            }}
          />
        )}
        <ResultImage item={result} style={{ cursor: 'pointer' }} />
      </div>

      <Stack gap={0} mt="xs">
        {dayjs(result.authoredOn).utc().format('MM/DD/YY h:mm a').toUpperCase()}

        <Group justify="space-between" wrap="nowrap">
          <Text fz="xs" fw="bold" tt="uppercase">
            {extractCompanyName(result)}
          </Text>
          {formatQuality(result.properties._dQuality)}
        </Group>
      </Stack>
    </ResultBase>
  )
}
