import type { LayerConfig } from '@/features/DantiMap/types'
import { getFirstSymbolId } from './get-first-symbol-id'

export const addLayer = ({
  map,
  layerId,
  isVisible = true,
  type,
  sourceId,
  pointIcon = 'default',
  lineColor = '#202020',
  lineOpacity = 0.8,
  lineWidth,
  fillOpacity,
  fillColor,
}: LayerConfig & { layerId: string; map: mapboxgl.Map | null }) => {
  if (map === null) {
    return
  }
  if (map.getLayer(layerId)) {
    const visibility = isVisible ? 'visible' : 'none'
    map.setLayoutProperty(layerId, 'visibility', visibility)
  } else {
    const visibility = isVisible ? 'visible' : 'none'
    const firstSymbolId = getFirstSymbolId(map)
    const hoverClause = ['boolean', ['feature-state', 'hover'], false]

    let layerDefinition
    switch (type) {
      case 'symbol':
        layerDefinition = {
          id: layerId,
          source: sourceId,
          type,
          layout: {
            visibility,
            'icon-image': pointIcon,
            'icon-size': 1,
            'icon-allow-overlap': true,
          },
        }
        break
      case 'fill':
        layerDefinition = {
          id: layerId,
          source: sourceId,
          type,
          layout: { visibility },
          paint: {
            'fill-opacity': fillOpacity || ['case', hoverClause, 0, 0.1],
            'fill-outline-color': lineColor,
            ...(fillColor ? { 'fill-color': fillColor } : {}),
          },
        }
        break
      case 'line':
        layerDefinition = {
          id: layerId,
          source: sourceId,
          type,
          layout: { visibility },
          paint: {
            'line-color': lineColor,
            'line-opacity': lineOpacity,
            ...(lineWidth ? { 'line-width': lineWidth } : {}),
          },
        }
        break
      case 'image':
        layerDefinition = {
          id: layerId,
          source: sourceId,
          type,
        }
        break
      case 'raster':
        layerDefinition = {
          id: layerId,
          source: sourceId,
          type,
        }
        break
      default:
        break
    }
    if (layerDefinition) {
      map?.addLayer(layerDefinition as mapboxgl.Layer, firstSymbolId)
    }
  }
}
