import { RESULT_CATEGORIES } from '@/utils/constants'
import type {
  DataResult,
  ImageryResult,
  PropertyResult,
  RawAnalyticResult,
  RawAttomResult,
  RawHazardPropertyResult,
  RawHazardRiskResult,
  RawPublicationResult,
  RawRelatedImageryResult,
  RawSocialMediaResult,
  ResultWithId,
} from '@/utils/types/raw-result-types.ts'
import {
  type AnalyticResult,
  type AttomResult,
  type HazardPropertyResult,
  type HazardRiskResult,
  type InsightResult,
  isAnalyticResult,
  isFireResult,
  isRelatedImageryResult,
  isShipResult,
  type PublicationResult,
  type RegridResult,
  type RelatedImageryResult,
  type SocialMediaResult,
} from '@/utils/types/result-types.ts'

export const loadGenericProps = (data: ResultWithId) => ({
  _dId: data.properties._dId,
  _dCategory: data.properties._dCategory,
  _dProvider: data.properties._dProvider,
  datetime: data.properties.datetime,
})

export const consumeRegridResult = (data: PropertyResult): RegridResult => {
  const { properties } = data as unknown as {
    properties: {
      _dAddress: string
      _dCategory: string
      _dProvider: string
      datetime: string
      address: string
      fields?: {
        ll_gisacre: number
        ll_gissqft: number
        ll_updated_at: string
        mail_city: string
        geoid: string
        county: string
        parcelnumb: string
        legaldesc: string
      }
    }
  }
  return {
    ...data,
    properties: {
      ...loadGenericProps(data),
      resultCategory: RESULT_CATEGORIES.REGRID,
      lotSizeAcres: properties.fields?.ll_gisacre,
      lotSizeSquareFeet: properties.fields?.ll_gissqft,
      address: properties._dAddress,
      city: properties.fields?.mail_city,
      geoid: properties.fields?.geoid,
      county: properties.fields?.county,
      parcelNumber: properties.fields?.parcelnumb,
      legalDescription: properties.fields?.legaldesc,
      updatedAt: properties.fields?.ll_updated_at,
    },
  }
}

const consumeAttomResult = (data: RawAttomResult): AttomResult => {
  return {
    ...data,
    properties: {
      ...loadGenericProps(data),
      resultCategory: RESULT_CATEGORIES.ATTOM,
      updatedAt: data.properties.updatedAt,
      address: data.properties.address.oneLine,
      buildingPermits: (
        data.properties.buildingPermits?.buildingPermits || []
      ).map((permit) => ({
        businessName: permit.businessName,
        description: permit.description,
        effectiveDate: permit.effectiveDate,
        homeOwnerName: permit.homeOwnerName,
        jobValue: permit.jobValue,
        permitNumber: permit.permitNumber,
        status: permit.status,
        type: permit.type,
      })),
    },
  }
}

const consumeHazardPropertyResult = (
  data: RawHazardPropertyResult,
): HazardPropertyResult => {
  return {
    ...data,
    properties: {
      ...loadGenericProps(data),
      resultCategory: RESULT_CATEGORIES.HAZARD_PROPERTY,
      datetime: data.properties.datetime,
      address: data.properties.property.address,
      useCode: data.properties.property.use_code,
      bathsTotal: data.properties.mls_listing_record_details?.rm_baths_total,
      bedroomsTotal:
        data.properties.mls_listing_record_details?.rm_bedrooms_total,
      yearBuilt: data.properties.mls_listing_record_details?.in_year_built,
      bathsPartial:
        data.properties.mls_listing_record_details?.rm_baths_partial,
      coolingFeatures:
        data.properties.mls_listing_record_details?.if_cooling_features,
      publicRemarks:
        data.properties.mls_listing_record_details?.in_public_remarks,
      images: data.properties.images?.images || [],
      listDate: data.properties.listing_record?.list_date,
      listPrice: data.properties.listing_record?.list_price,
      listStatus: data.properties.listing_record?.status,
    },
  }
}
const consumeHazardRiskResult = (
  data: RawHazardRiskResult,
): HazardRiskResult => {
  const { properties } = data
  return {
    ...data,
    properties: {
      ...loadGenericProps(data),
      resultCategory: RESULT_CATEGORIES.HAZARD_RISK,
      datetime: properties.datetime,
      address: properties.address,
      risks: properties.danti_highlights,
    },
  }
}

export const consumePropertyResult = (data: PropertyResult) => {
  if (data.properties.resultCategory) {
    return data
  }
  if (data.properties._dProvider === 'REGRID') {
    return consumeRegridResult(data)
  }
  if (data.properties._dProvider === 'ATTOM') {
    return consumeAttomResult(data as RawAttomResult)
  }
  if ((data as HazardPropertyResult).properties.property) {
    return consumeHazardPropertyResult(data as RawHazardPropertyResult)
  }
  return consumeHazardRiskResult(data as RawHazardRiskResult)
}

export const consumeImageData = (
  data: ImageryResult | RawAnalyticResult | RawRelatedImageryResult,
) => ({
  ...data,
  properties: {
    ...data.properties,
    _dQuality: data.properties._dQuality,
    _dCloudCover: data.properties._dCloudCover,
    _dCollect: data.properties._dCollect,
    _dSensor: data.properties._dSensor,
    featureId: data.properties.featureId,
  },
})

export const consumeAnalyticResult = (data: RawAnalyticResult) => {
  const result = consumeImageData(data)
  return {
    ...result,
    properties: {
      ...result.properties,
      resultCategory: RESULT_CATEGORIES.ANALYTIC,
    },
  }
}
export const consumeRelatedImageryResult = (data: RawRelatedImageryResult) => {
  const result = consumeImageData(data)
  return {
    ...result,
    properties: {
      ...result.properties,
      resultCategory: RESULT_CATEGORIES.RELATED_IMAGERY,
    },
  }
}
export const consumeImageryResult = (data: ImageryResult) => {
  const result = consumeImageData(data)
  return {
    ...result,
    properties: {
      ...result.properties,
      resultCategory: RESULT_CATEGORIES.IMAGE,
    },
  }
}

export const consumeImageResult = (
  data: ImageryResult | RawAnalyticResult | RawRelatedImageryResult,
) => {
  if (isRelatedImageryResult(data)) {
    return consumeRelatedImageryResult(data) as RelatedImageryResult
  }
  if (isAnalyticResult(data)) {
    return consumeAnalyticResult(data) as AnalyticResult
  }
  return consumeImageryResult(data) as ImageryResult
}

export const consumePublicatioResult = (data: RawPublicationResult) => {
  const embedded = data.properties._dEmbedded || []

  return {
    ...data,
    properties: {
      ...data.properties,
      resultCategory: RESULT_CATEGORIES.WEB_ARTICLE,
      _dEmbedded: embedded,
    },
  } as PublicationResult
}

export const consumeDataResult = (data: ResultWithId) => {
  let resultCategory = RESULT_CATEGORIES.DATA as string
  if (isFireResult(data)) {
    resultCategory = RESULT_CATEGORIES.FIRE
  }
  if (isShipResult(data)) {
    resultCategory = RESULT_CATEGORIES.SHIP
  }
  return {
    ...data,
    properties: { ...data.properties, resultCategory },
  } as DataResult
}

export const consumeSocialMediaResult = (
  data: RawSocialMediaResult,
): SocialMediaResult =>
  ({
    ...data,
    properties: {
      ...data.properties,
      resultCategory: RESULT_CATEGORIES.SOCIAL_MEDIA,
    },
  }) as SocialMediaResult

export const consumeInsightResult = (data: ResultWithId) =>
  ({
    ...data,
    properties: {
      ...data.properties,
      resultCategory: RESULT_CATEGORIES.INSIGHT,
    },
  }) as InsightResult

export const ingestTransforms = {
  [RESULT_CATEGORIES.PROPERTY]: consumePropertyResult,
  [RESULT_CATEGORIES.IMAGE]: consumeImageResult,
  [RESULT_CATEGORIES.PUBLICATION]: consumePublicatioResult,
  [RESULT_CATEGORIES.FIRE]: consumeDataResult,
  [RESULT_CATEGORIES.SHIP]: consumeDataResult,
  [RESULT_CATEGORIES.SOCIAL_MEDIA]: consumeSocialMediaResult,
  [RESULT_CATEGORIES.INSIGHT]: consumeInsightResult,
} as {
  [key: string]: (data: any) => any
}
