import * as React from 'react'
import { useLocation } from 'react-router-dom'
import {
  ActionIcon,
  Autocomplete,
  type AutocompleteProps,
  Button,
  Center,
  type ComboboxParsedItem,
  Drawer,
  em,
  Group,
  Stack,
  Text,
  Tooltip,
} from '@mantine/core'
import { useDisclosure, useMediaQuery } from '@mantine/hooks'
import { Icon } from '@/components/lib/Icon'
import {
  AUTOCOMPLETE_INPUT_PROPS,
  AUTOCOMPLETE_LOCATION_PLACEHOLDER,
  AUTOCOMPLETE_SUBJECT_PLACEHOLDER,
  AUTOCOMPLETE_TOOLTIP_PROPS,
  SEARCH_STYLE,
} from '@/features/Search/constants'
import { useSplitSearch } from '@/features/Search/hooks'
import { classes } from '@/utils/ui-helpers'

import styles from '@/features/Search/search-bar.module.css'

interface SplitSearchProps {
  handleInlineHotkeyPress: (event: KeyboardEvent | React.KeyboardEvent) => void
  doSplitSearch: (
    formattedAddress: string,
    query: string,
    subject: string,
    location: {
      formattedAddress: string
      placeId: string
      country: string
      adminAreaLevel1: string
      adminAreaLevel2: string
    },
  ) => void
}

const getLabel = (searchStyle: SEARCH_STYLE) => {
  switch (searchStyle) {
    case SEARCH_STYLE.General:
      return 'General'
    case SEARCH_STYLE.Location:
      return 'By Location'
  }
}

export const SplitSearch = React.forwardRef(
  ({ doSplitSearch, handleInlineHotkeyPress }: SplitSearchProps, ref) => {
    const {
      inputs,
      autoComplete,
      clearLocationValue,
      handleSubmit,
      locationProps,
    } = useSplitSearch({ doSplitSearch, ref })
    const [opened, { open, close }] = useDisclosure()
    const [searchStyle, setSearchStyle] = React.useState(SEARCH_STYLE.Location)
    const isMobile = useMediaQuery(`(max-width: ${em(750)})`)
    const { pathname } = useLocation()
    const isOverview = pathname.includes('/overview')

    const showLocation = React.useMemo(
      () => !isMobile || searchStyle == SEARCH_STYLE.Location,
      [isMobile, searchStyle],
    )

    const { locationValue } = inputs
    const { errorMessage } = autoComplete

    const hasError = !!errorMessage

    const customErrorAutocompleteOption: AutocompleteProps['renderOption'] = ({
      option,
    }) => {
      const { isError, value } = autoComplete.customErrorOption(option)
      const disabledClass = isError
        ? styles.customDisabledItem
        : styles.normalDisabledItem
      return (
        <div className={classes(styles.autoCompleteItem, disabledClass)}>
          {isError && <Icon name="error" className={styles.autoCompleteIcon} />}
          <Text size="sm" className={styles.autoCompleteText}>
            {value}
          </Text>
        </div>
      )
    }

    const locationAutoCompleteProps = {
      id: 'location-search',
      placeholder: AUTOCOMPLETE_LOCATION_PLACEHOLDER,
      onKeyDown: handleInlineHotkeyPress,
      leftSection: <Icon name="my_location" />,
      classNames: {
        input: styles.locationInput,
        root: styles.locationInputWrapper,
      },

      required: true,

      ...AUTOCOMPLETE_INPUT_PROPS,
      ...locationProps,

      filter: ({ options }: { options: ComboboxParsedItem[] }) => options,
      renderOption: customErrorAutocompleteOption,

      rightSection: autoComplete.suggestionSelected && (
        <Icon name="highlight_off" onClick={clearLocationValue} />
      ),
    }

    const subjectAutoCompleteProps = {
      id: 'subject-search',
      placeholder: AUTOCOMPLETE_SUBJECT_PLACEHOLDER,
      onKeyDown: handleInlineHotkeyPress,
      leftSection: <Icon name="search" />,
      classNames: {
        input: styles.subjectInput,
        root: styles.subjectInputWrapper,
      },

      ...AUTOCOMPLETE_INPUT_PROPS,
      ...inputs.subjectProps,
    } as const

    const canSearch =
      searchStyle == SEARCH_STYLE.General || !!locationValue.trim()

    const processSubmit = (
      event?:
        | React.FormEvent<HTMLFormElement>
        | KeyboardEvent
        | React.MouseEvent,
    ) => {
      event?.stopPropagation()
      handleSubmit(searchStyle)
    }
    const form = (
      <form onSubmit={processSubmit} className={styles.searchForm}>
        {showLocation && (
          <>
            <Tooltip
              label={errorMessage}
              opened={hasError}
              {...AUTOCOMPLETE_TOOLTIP_PROPS}
            >
              <Autocomplete {...locationAutoCompleteProps} />
            </Tooltip>
            <Icon
              name="add"
              size={24}
              weight={700}
              className={styles.splitSearchDivider}
            />
          </>
        )}
        <Autocomplete {...subjectAutoCompleteProps} />
        <ActionIcon
          variant="transparent"
          component="button"
          type="submit"
          className={canSearch ? styles.sendButton : styles.sendButtonDisabled}
          onClick={() => handleSubmit(searchStyle)}
          disabled={!canSearch}
        >
          <Icon name="send" filled />
        </ActionIcon>
      </form>
    )

    return (
      <div style={{ width: '100%' }}>
        <Group
          visibleFrom="sm"
          gap={0}
          w="100%"
          className={styles.splitSearchGroup}
        >
          {form}
        </Group>

        <Group hiddenFrom="sm" w="100%" className={styles.splitSearchGroup}>
          <ActionIcon
            className={classes(
              opened
                ? styles.mobileSplitSearchCloseIcon
                : styles.mobileSplitSearchIcon,
              opened && searchStyle == SEARCH_STYLE.General
                ? isOverview
                  ? styles.mobileSplitSearchCloseIconGeneralOverview
                  : styles.mobileSplitSearchCloseIconGeneral
                : undefined,
              isOverview
                ? styles.mobileSplitSearchCloseIconOverview
                : undefined,
            )}
            component={Icon}
            name={opened ? 'close' : 'search'}
            onClick={opened ? close : open}
          />
          <Drawer
            opened={opened}
            onClose={close}
            position="bottom"
            withCloseButton={false}
            radius="md"
            overlayProps={{ backgroundOpacity: 0 }}
            classNames={{
              content: classes(
                styles.splitSearchDrawer,
                searchStyle == SEARCH_STYLE.General
                  ? styles.splitSearchDrawerGeneral
                  : styles.splitSearchDrawerLocation,
              ),
              body: styles.splitSearchDrawerBody,
            }}
          >
            <Stack px="xs" gap="xs" mt={-4}>
              {form}
              <Center>
                <Group gap="md" ml={-10}>
                  <Text c="#00000099" size="sm">
                    Search style
                  </Text>
                  {Object.values(SEARCH_STYLE).map((x) => (
                    <Button
                      classNames={{
                        label:
                          searchStyle === x
                            ? styles.searchStyleButtonSelected
                            : styles.searchStyleButton,
                      }}
                      key={x}
                      onClick={() => setSearchStyle(x as SEARCH_STYLE)}
                      variant="transparent"
                      p={0}
                    >
                      {getLabel(x as SEARCH_STYLE)}
                    </Button>
                  ))}
                </Group>
              </Center>
            </Stack>
          </Drawer>
        </Group>
      </div>
    )
  },
)

SplitSearch.displayName = 'SplitSearch'

export default SplitSearch
