import { getRequest } from '@/api/danti-requests'
import { createSyncStoragePersister } from '@tanstack/query-sync-storage-persister'
import { QueryClient } from '@tanstack/react-query'
import { PersistQueryClientProvider } from '@tanstack/react-query-persist-client'
import type { PropsWithChildren } from 'react'

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      queryFn: async ({ queryKey: [url] }) => {
        if (typeof url === 'string') {
          const { data } = await getRequest(url)
          return data
        }
        throw new Error('Invalid query key')
      },
      staleTime: 1000 * 60 * 5, // 5 minutes (1000 * 60 * 5)
      gcTime: 1000 * 60 * 60, // 1 hour (1000 * 60 * 60)
      refetchOnWindowFocus: false,
    },
  },
})

const persister = createSyncStoragePersister({
  storage: window.localStorage,
})

export function QueryProvider({ children }: PropsWithChildren) {
  return (
    <PersistQueryClientProvider
      client={queryClient}
      persistOptions={{ persister }}
      onSuccess={() => {
        console.log('persisted')
      }}
    >
      {children}
    </PersistQueryClientProvider>
  )
}
