import { useEffect, useState } from 'react'
import {
  ActionIcon,
  Collapse,
  Group,
  Indicator,
  Stack,
  Tooltip,
} from '@mantine/core'
import { Icon } from '@/components/lib/Icon.tsx'
import { PostfilterCheckboxButton } from '@/components/lib/results/results-postfilter/PostfilterCheckboxButton'
import { PostfilterCloudButton } from '@/components/lib/results/results-postfilter/PostfilterCloudButton'
import { PostFilterQualityButton } from '@/components/lib/results/results-postfilter/PostfilterQualityButton'
import { useSearchStatus } from '@/features/Search/hooks'
import { useImageResultsPropertyHistogram } from '@/hooks/results/use-image-results-property-histogram'
import {
  useCloudCeil,
  useOmittedAssets,
  useOmittedRepos,
  useOmittedSensors,
  useOmittedSources,
  useQualityCeil,
  useSetCloudCeil,
  useSetOmittedAssets,
  useSetOmittedRepos,
  useSetOmittedSensors,
  useSetOmittedSources,
  useSetQualityCeil,
} from '@/stores/results-filter-store'
import type { PostFilterType } from '@/stores/results-filter-store/types'
import type { SymbolName } from '@/utils/types/material-icons'

export const ImageResultPostfilterBar = () => {
  const omittedSources = useOmittedSources()
  const omittedRepos = useOmittedRepos()
  const omittedAssets = useOmittedAssets()
  const omittedSensors = useOmittedSensors()
  const qualityCeil = useQualityCeil()
  const cloudCeil = useCloudCeil()
  const setOmittedSources = useSetOmittedSources()
  const setOmittedRepos = useSetOmittedRepos()
  const setOmittedAssets = useSetOmittedAssets()
  const setOmittedSensors = useSetOmittedSensors()
  const setQualityCeil = useSetQualityCeil()
  const setCloudCeil = useSetCloudCeil()

  const { source, repo, sensor, asset } = useImageResultsPropertyHistogram()
  const { isReadyForInteraction } = useSearchStatus()

  const [activePanel, setActivePanel] = useState<PostFilterType | null>(null)
  const [panelOpen, setPanelOpen] = useState(false)

  useEffect(() => {
    if (!isReadyForInteraction) {
      setPanelOpen(false)
      setActivePanel(null)
    }
  }, [isReadyForInteraction])

  const makeActionButton = (
    mode: PostFilterType,
    icon: SymbolName,
    tooltip: string,
    isActive: boolean,
  ) => {
    const actionIcon = (
      <Tooltip label={tooltip}>
        <ActionIcon
          disabled={!isReadyForInteraction}
          onClick={() => {
            if (activePanel === mode) {
              setPanelOpen(false)
            } else {
              setActivePanel(mode)
              setPanelOpen(true)
            }
          }}
          variant={activePanel === mode && panelOpen ? undefined : 'subtle'}
        >
          <Icon name={icon} />
        </ActionIcon>
      </Tooltip>
    )

    const indicatorColor = isActive ? 'blue' : '#00000000'

    return <Indicator color={indicatorColor}>{actionIcon}</Indicator>
  }
  return (
    <Stack gap="xs" align="flex-start" pos="relative">
      <Group align="baseline" justify="space-around" w="100%">
        {makeActionButton(
          'source',
          'satellite_alt',
          'Sources',
          omittedSources.length > 0,
        )}
        {makeActionButton(
          'repo',
          'database',
          'Repositories',
          omittedRepos.length > 0,
        )}
        {makeActionButton(
          'sensor',
          'camera',
          'Sensors',
          omittedSensors.length > 0,
        )}
        {makeActionButton(
          'quality',
          'background_grid_small',
          'Quality',
          (qualityCeil || 4) < 4,
        )}
        {makeActionButton(
          'cloud',
          'cloud',
          'Cloud Cover',
          (cloudCeil || 5) < 5,
        )}
      </Group>
      <Collapse
        in={panelOpen}
        w="100%"
        pb="xs"
        onTransitionEnd={() => {
          if (!panelOpen) {
            setActivePanel(null)
          }
        }}
      >
        {activePanel === 'source' ? (
          <PostfilterCheckboxButton
            label="Image Source"
            histogrammedOptions={source}
            omittedOptions={omittedSources}
            setNewOmittedOptions={setOmittedSources}
          />
        ) : activePanel === 'repo' ? (
          <PostfilterCheckboxButton
            label="Image Repository"
            histogrammedOptions={repo}
            omittedOptions={omittedRepos}
            setNewOmittedOptions={setOmittedRepos}
          />
        ) : activePanel === 'asset' ? (
          <PostfilterCheckboxButton
            label="Image Style"
            histogrammedOptions={asset}
            omittedOptions={omittedAssets}
            setNewOmittedOptions={setOmittedAssets}
          />
        ) : activePanel === 'sensor' ? (
          <PostfilterCheckboxButton
            label="Image Sensor"
            histogrammedOptions={sensor}
            omittedOptions={omittedSensors}
            setNewOmittedOptions={setOmittedSensors}
          />
        ) : activePanel === 'quality' ? (
          <PostFilterQualityButton
            currentCeil={qualityCeil}
            setCeil={setQualityCeil}
          />
        ) : activePanel === 'cloud' ? (
          <PostfilterCloudButton
            currentCeil={cloudCeil}
            setCeil={setCloudCeil}
          />
        ) : (
          <></>
        )}
      </Collapse>
    </Stack>
  )
}
