import { useNavigate } from 'react-router-dom'
import { ActionIcon } from '@mantine/core'
import { Icon } from '@/components/lib/Icon'
import { useBackNavigation } from '@/features/AppRouting/hooks'

export interface BackButtonProps {
  hiddenFrom?: 'md' | 'sm'
  path?: string
}

export function BackButton({ hiddenFrom, path }: BackButtonProps) {
  const navigate = useNavigate()
  const goBack = useBackNavigation()

  const handleClick = () => {
    if (path) {
      navigate(path)
    } else {
      goBack()
    }
  }

  return (
    <ActionIcon
      onClick={handleClick}
      radius="xl"
      left={5}
      p="0"
      variant="outline"
      color="#0000008A"
      hiddenFrom={hiddenFrom}
      style={{
        zIndex: 1,
        color: '#666',
        borderColor: '#BDBDBD',
      }}
    >
      <Icon name="arrow_back" />
    </ActionIcon>
  )
}
