import { useNavigate } from 'react-router-dom'
import {
  ActionIcon,
  Checkbox,
  Group,
  Image,
  Menu,
  Paper,
  Stack,
  Text,
} from '@mantine/core'
import { useThumbnailProxy } from '@/api/thumbnail'
import { DantiNoResultMark } from '@/components/lib/DantiNoResultMark.tsx'
import { Icon } from '@/components/lib/Icon'
import {
  openAddToCollectionModal,
  openDownloadItemModal,
  openResultDetailsModal,
} from '@/components/modals/openers'
import { makeOrthoLayer } from '@/features/DantiMap/utils'
import { useIsDownloadable } from '@/hooks/results/use-is-downloadable'
import { useSubscribeScrollIntoView } from '@/hooks/use-subscribe-scroll-into-view.ts'
import {
  useActiveOrthoLayerGroupIds,
  useClearOrthoLayerGroupIds,
  useSetHoveredLayerId,
  useToggleOrthoLayerGroupId,
  useZoomToOrthoLayer,
} from '@/stores/map-store'
import {
  extractAssetName,
  extractSensorName,
} from '@/stores/results-filter-store/utils'
import type { SymbolName } from '@/utils/types/material-icons'
import type { OrthoImageryLayerGroup } from '@/utils/types/ortho-imagery-types'
import { isImageryResult, type ResultWithId } from '@/utils/types/result-types'
import { captureException } from '@sentry/react'
import dayjs from 'dayjs'
import LocalizedFormat from 'dayjs/plugin/localizedFormat'
import utc from 'dayjs/plugin/utc'
import pluralize from 'pluralize'
import type React from 'react'

import styles from '@/components/lib/thumbnail.module.css'

export interface ThumbnailProps extends React.HTMLAttributes<HTMLImageElement> {
  item: OrthoImageryLayerGroup
}

dayjs.extend(LocalizedFormat)
dayjs.extend(utc)

interface ActionMenuType {
  label: string
  icon: SymbolName
  onClick: (result: ResultWithId) => void
  title?: string
  disabled?: boolean
}

const actionMenuOptions: Record<string, ActionMenuType> = {
  addToCollection: {
    label: 'Add to Saved Search',
    icon: 'add',
    onClick: (result: ResultWithId) => {
      openAddToCollectionModal(result)
    },
  },
  viewDetails: {
    label: 'View Details',
    icon: 'info',
    onClick: (result: ResultWithId) => {
      openResultDetailsModal(result)
    },
  },
  downloadImage: {
    label: 'Download',
    icon: 'download',
    onClick: (result: ResultWithId) => {
      if (isImageryResult(result)) {
        openDownloadItemModal(result)
      }
    },
    disabled: true,
  },
}

export const OrthoThumbnail = ({ item }: ThumbnailProps) => {
  const { id, layers, authoredOn, formattedSource } = item
  const navigate = useNavigate()
  const activeOrthoLayerIds = useActiveOrthoLayerGroupIds()
  const setHoveredLayerId = useSetHoveredLayerId()
  const clearOrthoLayerGroupIds = useClearOrthoLayerGroupIds()
  const toggleOrthoLayerId = useToggleOrthoLayerGroupId()
  const zoomToOrthoLayer = useZoomToOrthoLayer()
  const { targetRef } = useSubscribeScrollIntoView(
    item.id,
    activeOrthoLayerIds.find((layerId) => layerId === id),
  )

  const result = layers[0].imageResult
  const { data: isDownloadable } = useIsDownloadable(result)

  const { title, thumbnail, source } = result

  const { data: hasThumbnail } = useThumbnailProxy(
    { thumbnail: thumbnail ?? '', source },
    Boolean(thumbnail),
  )

  const adjustedActionMenuOptions: Record<string, ActionMenuType> = {
    ...actionMenuOptions,
    downloadImage: {
      ...actionMenuOptions['downloadImage'],
      disabled: !isDownloadable,
      title: isDownloadable ? undefined : 'Not available for download',
    },
    viewDetails: {
      ...actionMenuOptions['viewDetails'],
      onClick: (clickedResult: ResultWithId) => {
        if (isImageryResult(clickedResult)) {
          const layer = makeOrthoLayer(clickedResult)
          if (layer) {
            clearOrthoLayerGroupIds()
            toggleOrthoLayerId(layer.groupKey)
            navigate(`/results/${clickedResult.id}`)
          } else {
            captureException(
              `Could not correlate result id ${clickedResult.id} to existing ortholayer`,
            )
          }
        } else {
          openResultDetailsModal(clickedResult)
        }
      },
    },
  }

  const actions = Object.values(adjustedActionMenuOptions)

  return (
    <Paper
      onMouseEnter={() => setHoveredLayerId(id)}
      onMouseLeave={() => setHoveredLayerId(null)}
      p="xs"
      mx="-xs"
      ref={targetRef}
    >
      <Group
        pos="relative"
        gap="sm"
        p="sm"
        style={{
          border: `1px solid ${activeOrthoLayerIds.includes(id) ? '#0091EA' : '#E0E0E0'}`,
          borderRadius: 'var(--mantine-radius-sm)',
        }}
      >
        <div style={{ position: 'relative', width: '43px', height: '66px' }}>
          <Checkbox
            style={{
              position: 'absolute',
              top: 'calc(var(--mantine-spacing-xs) * -1)',
              left: 'calc(var(--mantine-spacing-xs) * -1)',
            }}
            checked={activeOrthoLayerIds.includes(id)}
            onChange={() => {
              if (!activeOrthoLayerIds.includes(id)) {
                zoomToOrthoLayer(id)
              }
              toggleOrthoLayerId(id)
            }}
            size="xs"
          />
          {hasThumbnail ? (
            <Image
              alt={title}
              src={hasThumbnail}
              style={{ height: '100%' }}
              data-cy="ortho-image-thumbnail"
            />
          ) : (
            <DantiNoResultMark />
          )}
        </div>

        <Stack gap="xs">
          <div>
            <Text size="xs" fw="bold">
              {formattedSource.toUpperCase()}
            </Text>
            <Text size="xs" c="#666666">
              {layers.length > 1 &&
                ` - ${layers.length} ${pluralize('IMAGE', layers.length)}`}
              {'  '}
              {extractSensorName(result).toUpperCase() ?? ''}
              {'  '}
              {extractAssetName(result).toUpperCase() ?? ''}
            </Text>
          </div>

          <Group gap="xs">
            <Text size="xs">
              {dayjs(authoredOn)
                .utc()
                .format('MMM D, YYYY HH:mm')
                .toUpperCase()}{' '}
              Z
            </Text>
            <Text size="xs">
              QUALITY: {result.properties._dQuality}m CLOUD COVER:
              {'  '}
              {result.properties._dCloudCover}%
            </Text>
          </Group>
        </Stack>
        <Menu trigger="hover" shadow="md">
          <Menu.Target>
            <ActionIcon
              variant="white"
              className={styles['clickable']}
              onClick={(event) => event.stopPropagation()}
            >
              <Icon name="more_vert" size="xl" />
            </ActionIcon>
          </Menu.Target>
          <Menu.Dropdown>
            {actions.map(
              ({ label, icon, onClick: actionOnClick, ...passProps }) => {
                return label === 'Download' && !isDownloadable ? null : (
                  <Menu.Item
                    key={label}
                    {...passProps}
                    leftSection={<Icon name={icon} />}
                    onClick={(event) => {
                      event.stopPropagation()
                      void actionOnClick(result)
                    }}
                    p="xs"
                    className={styles['menu-hover']}
                  >
                    {label}
                  </Menu.Item>
                )
              },
            )}
          </Menu.Dropdown>
        </Menu>
      </Group>
    </Paper>
  )
}
