import { useNavigate } from 'react-router-dom'
import { Stack, Text } from '@mantine/core'
import { warnExternalLink } from '@/features/ExternalLink'
import { InsightFeedbackPanel } from '@/features/InsightPanel/components/InsightFeedback'
import type { InsightsPanelProps } from '@/features/InsightPanel/types'
import { useFeatureFlag } from '@/hooks/use-feature-flag'
import { FEATURE_FLAG_VALUES } from '@/utils/constants'
import ReactMarkdown from 'react-markdown'

import styles from './insight-panel.module.css'

const CustomLink = ({
  href,
  children,
}: {
  href: string
  children: React.ReactNode
}) => {
  const navigate = useNavigate()

  const handleClick = (event: React.MouseEvent<HTMLAnchorElement>) => {
    event.preventDefault()

    // Check if the link is internal (starts with '/')
    if (href.startsWith('/')) {
      navigate(href)
    } else {
      warnExternalLink(href)
    }
  }

  return (
    <a href={href} onClick={handleClick}>
      {children}
    </a>
  )
}

export function InsightPanel({ result }: InsightsPanelProps) {
  const isFeedbackEnabled = useFeatureFlag(FEATURE_FLAG_VALUES.insightFeedback)

  return (
    <Stack component="div" p={0}>
      <Text className={styles.markdown} span>
        <ReactMarkdown
          components={{
            a: ({
              href,
              children,
              ...props
            }: React.AnchorHTMLAttributes<HTMLAnchorElement>) => (
              <CustomLink href={href || ''} {...props}>
                {children}
              </CustomLink>
            ),
          }}
        >
          {result.description}
        </ReactMarkdown>
      </Text>
      {isFeedbackEnabled && <InsightFeedbackPanel />}
    </Stack>
  )
}
