import type {
  CategorizedResults,
  ImageryResult,
  ResultWithId,
} from '@/utils/types/result-types.ts'
import * as Sentry from '@sentry/react'

export type ResultPredicate = (r: ResultWithId) => boolean

export const filterCategorizedResults = (
  cr: CategorizedResults,
  predicate: ResultPredicate,
): CategorizedResults => {
  return {
    imageResults: cr.imageResults.filter((r) => predicate(r)),
    dataResults: cr.dataResults.filter((r) => predicate(r)),
    publicationResults: cr.publicationResults.filter((r) => predicate(r)),
    socialMediaResults: cr.socialMediaResults.filter((r) => predicate(r)),
    insightResults: cr.insightResults,
    propertyResults: cr.propertyResults.filter((r) => predicate(r)),
  }
}

type Results = {
  ids: string[]
  categorized: CategorizedResults
  currentQueryId: string
}

export const flattenResults = (
  categorized: CategorizedResults,
): ResultWithId[] => {
  const withoutInsights: Partial<CategorizedResults> = { ...categorized }
  delete withoutInsights.insightResults
  return Object.values(withoutInsights).flat() as ResultWithId[]
}

export const filterResultsById = (
  predicate: ResultPredicate,
  unfilteredResults: Results,
): Results => {
  const results = flattenResults(unfilteredResults.categorized).filter((r) =>
    predicate(r),
  )
  const categorized = filterCategorizedResults(
    unfilteredResults.categorized,
    predicate,
  )
  const ids = results.map((r) => r.id)

  return {
    currentQueryId: unfilteredResults.currentQueryId,
    categorized,
    ids,
  }
}

export const extractCompanyName = (ir: ImageryResult) => {
  const provider = ir.properties._dProvider
  const source = ir.properties._dSource
  if (provider.toUpperCase() == source?.toUpperCase() || !source) {
    return provider
  }
  return `${source} (${provider})`
}

export const extractAssetName = (ir: ImageryResult) => {
  const sensorProperty = ir.properties._dSensor as string
  if (!sensorProperty) {
    return 'Unknown'
  }
  switch (sensorProperty) {
    case 'EO':
      return 'Natural Color'
    case 'SAR':
      return 'Panchromatic'
    default:
      console.warn(`Encountered unknown asset property for: ${sensorProperty}`)
      Sentry.captureMessage(
        `Encountered unknown asset property for: ${sensorProperty}`,
        { level: 'warning', tags: { resultId: ir.id } },
      )
      return 'Unknown'
  }
}

export const extractSensorName = (ir: ImageryResult) => {
  const sensorProperty = ir.properties._dSensor as string
  if (!sensorProperty) {
    return 'Unknown'
  }
  return sensorProperty
}
