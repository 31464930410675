import { useImageById } from '@/api/results'
import { SingleImageMap } from '@/features/DantiMap'

export const TimelineInspect = ({
  selectedImage,
  setSelectedImage,
}: {
  selectedImage: string | null
  setSelectedImage: (image: string | null) => void
}) => {
  console.log({ selectedImage, setSelectedImage })
  const image = useImageById(selectedImage)
  console.log({ image })
  return (
    <div>
      <h2>Inspect Component</h2>
      <div style={{ border: '4px solid blue' }}>
        {image && <SingleImageMap key={image.id} item={image} />}
      </div>
    </div>
  )
}
