import React from 'react'
import { type CardProps, Group, Stack, Text } from '@mantine/core'
import { Icon } from '@/components/lib/Icon.tsx'
import { ResultBase } from '@/components/lib/results/Result/ResultBase.tsx'
import { useSubscribeScrollIntoView } from '@/hooks/use-subscribe-scroll-into-view.ts'
import { useSelectedPointId, useSetSelectedPointId } from '@/stores/map-store'
import type { CollectionItem } from '@/api/collections/types'
import type { ResultWithId } from '@/utils/types/result-types.ts'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'

import styles from './result-base.module.css'

dayjs.extend(utc)

interface NewsResultProps extends CardProps {
  item?: CollectionItem
  result: ResultWithId
  isFullscreen?: boolean
  listView?: boolean
}

export function NewsResult({
  result,
  isFullscreen,
  listView = true,
  ...props
}: NewsResultProps) {
  const selectedPointId = useSelectedPointId()
  const setSelectedPointId = useSetSelectedPointId()
  const { targetRef } = useSubscribeScrollIntoView(result.id, selectedPointId)

  React.useEffect(() => {
    isFullscreen && setSelectedPointId(null)
  }, [isFullscreen, setSelectedPointId])

  return (
    <ResultBase
      disableNavigate={true}
      p={selectedPointId === result.documentId ? 'xs' : ''}
      result={result}
      onClick="visitArticle"
      className={styles[listView ? 'separated' : 'box']}
      data-cy="newsResult"
      ref={targetRef}
      style={{
        boxShadow:
          selectedPointId === result.documentId
            ? '0px -1px 1px 0px rgba(12, 44, 125, 0.20), 0px 1px 1px 0px rgba(0, 0, 0, 0.18), 0px 0px 1px 0px rgba(0, 0, 0, 0.27) inset'
            : '',
        border:
          selectedPointId === result.documentId ? '1px solid #0091EA' : 'none',
      }}
      {...props}
    >
      <Stack
        gap="xs"
        mb="sm"
        h="100%"
        title={result.title}
        style={{ aspectRatio: isFullscreen ? 2.8 : 'auto' }}
      >
        <Text
          className={styles['link-ish']}
          fz={isFullscreen ? 'sm' : 'xs'}
          lineClamp={isFullscreen ? 2 : 3}
          fw="bold"
        >
          {result.title}
        </Text>
        <Group gap="xs" wrap="nowrap">
          {!isFullscreen && <Icon name="calendar_today" size="sm" />}
          <Text fz={isFullscreen ? 'sm' : 'xs'} truncate="end">
            {dayjs(result.properties?.datetime).utc().format('MMMM DD, YYYY')}
          </Text>
        </Group>
        {isFullscreen && <Text fw="bold">{result.source}</Text>}
      </Stack>
    </ResultBase>
  )
}
