import React from 'react'
import { Chip, Group, Popover, Text } from '@mantine/core'
import { DatePicker } from '@mantine/dates'
import { Icon } from '@/components/lib/Icon.tsx'
import {
  useCustomTimeEndFilter,
  useCustomTimeStartFilter,
} from '@/stores/filters-store'
import { type DateValue, TimePreset } from '@/utils/types/filter-types.ts'

import '@mantine/dates/styles.css'

const timeFrames = [
  { label: 'Past 24 hours', value: TimePreset.Last24Hours },
  { label: 'Past 7 days', value: TimePreset.Last7Days },
  { label: 'Past 30 days', value: TimePreset.Last30Days },
  { label: 'Past 90 days', value: TimePreset.Last90Days },
]
export interface TimeframeProps {
  selectedTimePreset: TimePreset
  onSelect: (
    timePreset: TimePreset,
    start: DateValue | null,
    end: DateValue | null,
  ) => void
  disabled: boolean
}
export function Timeframe({
  onSelect,
  selectedTimePreset,
  disabled,
}: TimeframeProps) {
  const customTimeStart = useCustomTimeStartFilter()
  const customTimeEnd = useCustomTimeEndFilter()

  const [isDatePickerOpen, setIsDatePickerOpen] = React.useState(false)
  const popupRef = React.useRef<HTMLInputElement>(null)

  const timeStart = customTimeStart ? new Date(customTimeStart) : null
  const timeEnd = customTimeEnd ? new Date(customTimeEnd) : null
  const isCustomTimeSet = Boolean(
    selectedTimePreset === TimePreset.Custom && (timeStart || timeEnd),
  )

  function handleChange([start, end]: DateValue[]) {
    onSelect(TimePreset.Custom, start ?? null, end ?? null)
    if (start && end) {
      popupRef.current?.click()
    }
  }

  return (
    <Chip.Group>
      <Group gap="xs">
        {timeFrames.map(({ label, value }) => (
          <Chip
            key={value}
            variant={selectedTimePreset === value ? 'filled' : 'outline'}
            disabled={disabled}
            defaultChecked={false}
            checked={!disabled && selectedTimePreset === value}
            size="xs"
            onClick={() => onSelect(value, null, null)}
          >
            {label}
          </Chip>
        ))}
        <Popover shadow="sm" position="top" withArrow opened={isDatePickerOpen}>
          <Popover.Target>
            <Chip
              size="xs"
              variant={isCustomTimeSet ? 'filled' : 'outline'}
              disabled={disabled}
              checked={!disabled && isCustomTimeSet}
              ref={popupRef}
              onClick={() => setIsDatePickerOpen((state) => !state)}
            >
              <Group gap={4}>
                <Icon name="calendar_month" size={16} />
                <Text size="xs">
                  {timeStart || timeEnd
                    ? `${timeStart?.toLocaleDateString() ?? ''}${
                        timeStart && timeEnd ? ` - ` : ''
                      }${timeEnd?.toLocaleDateString() ?? ''}`
                    : 'Custom'}
                </Text>
              </Group>
            </Chip>
          </Popover.Target>
          <Popover.Dropdown>
            <DatePicker
              type="range"
              size="xs"
              allowSingleDateInRange
              aria-label="Custom date range"
              value={[timeStart, timeEnd]}
              onChange={handleChange}
              mx="auto"
              maw={400}
            />
          </Popover.Dropdown>
        </Popover>
      </Group>
    </Chip.Group>
  )
}
