import { getShipDetailCardProps } from '@/api/results'
import { GeometryCard } from '@/features/ResultCards/ReferenceCards/components'
import { RESULT_CATEGORY_COLORS } from '@/utils/constants'
import type { ShipResult } from '@/utils/types/result-types'

export const ShipReferenceCard = ({ ship }: { ship: ShipResult }) => (
  <GeometryCard
    {...getShipDetailCardProps(ship)}
    color={RESULT_CATEGORY_COLORS.ship.toLowerCase().slice(1)}
  />
)
