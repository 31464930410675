import { axiosClient } from '@/api/danti-requests/axios-client'
import { providerObject } from '@/components/filters/providers/utils'
import { graphql } from '@/gql'
import { useFeatureFlag } from '@/hooks/use-feature-flag'
import { useProvidersFilter, useSetProviders } from '@/stores/filters-store'
import { FEATURE_FLAG_VALUES } from '@/utils/constants'
import { searchApiUrl } from '@/utils/constants.ts'
import { useQuery } from '@apollo/client'
import * as reactQuery from '@tanstack/react-query'

export interface ProviderSource {
  source: string
  provider: string
  category: string
}

export interface ProviderColumn {
  label: string
  categories: string[]
  providers: ProviderSource[]
}

export const NON_DEFAULT_CATEGORIES = [
  'IMAGE',
  'SOCIAL_MEDIA',
  'WEB_ARTICLE',
  'PROPERTY',
  'HAZARD_PROPERTY',
  'HAZARD_RISK',
]

export const PROVIDER_COLUMN_TEMPLATE: ProviderColumn[] = [
  {
    label: 'Images',
    categories: ['IMAGE'],
    providers: [],
  },
  {
    label: 'News & Social',
    categories: ['SOCIAL_MEDIA', 'WEB_ARTICLE'],
    providers: [],
  },
  {
    label: 'Measurements',
    categories: ['*'],
    providers: [],
  },
  {
    label: 'Properties',
    categories: ['PROPERTY', 'HAZARD_PROPERTY', 'HAZARD_RISK'],
    providers: [],
  },
]

export interface SearchMetadataResponse {
  categories: string[]
  providers: string[]
  sources: ProviderSource[]
  sensors: string[]
}

export const SourcesByUserQuery = graphql(`
  query SourcesByUser {
    me {
      id
      sources {
        nodes {
          provider
        }
      }
    }
  }
`)

export function useProviders() {
  const shouldSupportDynamicProviders = useFeatureFlag(
    FEATURE_FLAG_VALUES.dynamicProviders,
  )
  const selectedProviders = useProvidersFilter()
  const setProviders = useSetProviders()
  const { data } = useQuery(SourcesByUserQuery, {
    skip: !shouldSupportDynamicProviders,
  })

  const { data: searchMetadata } = reactQuery.useQuery({
    queryKey: ['search-metadata'],
    queryFn: () =>
      axiosClient.get<SearchMetadataResponse>('/search/metadata', {
        baseURL: searchApiUrl,
      }),
  })

  const providersList =
    data?.me?.sources.nodes.map((source) => source.provider) || []
  const providers = providerObject(providersList)

  const updateProviders = (selectedProviders: string[]) => {
    const providersArray = selectedProviders.map((provider) => {
      const providerKey = Object.keys(providers).find((key) => key === provider)
      return providerKey || ''
    })
    setProviders(providersArray)
  }

  return {
    providers,
    newProviders: searchMetadata?.data.sources ?? [],
    updateProviders,
    selectedProviders,
  }
}
