import { extractCompanyName } from '@/stores/results-filter-store/utils'
import { apiUrl } from '@/utils/constants.ts'
import type { OrthoImageryLayer } from '@/utils/types/ortho-imagery-types.ts'
import type { ImageryResult } from '@/utils/types/result-types.ts'
import type { Polygon } from 'geojson'

const SKYSAT_TYPE = 'SkySatScene'

export const makePlanetOrthoLayer = (ir: ImageryResult): OrthoImageryLayer => {
  const planetType =
    /item-types\/([^/]*)\//.exec(ir.thumbnail)?.[1] || SKYSAT_TYPE
  const dId = ir.properties._dId
  const planetId = dId || ir.id
  return {
    source: 'Planet Labs' as const,
    formattedSource: `${extractCompanyName(ir)}`,
    score: ir.score,
    id: `${planetType}/${planetId}`,
    tileUrl: `${apiUrl}/provider/planet/data/v1/${planetType}/${planetId}/{z}/{x}/{y}.png`,
    documentId: ir.documentId,
    authoredOn: ir.authoredOn,
    geometry: ir.geometry as Polygon,
    imageResult: ir,
    groupKey: dId?.split('_').slice(0, 2).join('_') || dId || ir.id,
  }
}

export const isPlanet = (ir: ImageryResult) =>
  ir.source.toLowerCase() === 'planet labs'
