import React from 'react'
import { type CardProps, Image, Stack, Text } from '@mantine/core'
import { ResultBase } from '@/components/lib/results/Result/ResultBase.tsx'
import { useSubscribeScrollIntoView } from '@/hooks/use-subscribe-scroll-into-view.ts'
import { useSelectedPointId, useSetSelectedPointId } from '@/stores/map-store'
import type { SocialMediaResult } from '@/utils/types/result-types.ts'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'

dayjs.extend(utc)

interface SocialResultProps extends CardProps {
  result: SocialMediaResult
  isFullscreen?: boolean
}

export function SocialResult({
  result,
  isFullscreen,
  ...props
}: SocialResultProps) {
  const imageRef = React.useRef<HTMLDivElement>(null)
  const [maxHeight, setMaxHeight] = React.useState(100)
  const [showImage, setShowImage] = React.useState(
    Boolean(result.properties.src_image_url ?? result.thumbnail ?? false),
  )
  const selectedPointId = useSelectedPointId()
  const setSelectedPointId = useSetSelectedPointId()
  const { targetRef } = useSubscribeScrollIntoView(result.id, selectedPointId)

  React.useEffect(() => {
    if (isFullscreen) {
      setSelectedPointId(null)
    }
  }, [isFullscreen, setSelectedPointId])

  React.useLayoutEffect(() => {
    if (targetRef.current) {
      setMaxHeight(
        targetRef.current.clientHeight -
          (imageRef.current?.clientHeight ?? 0) -
          32,
      )
    }
  }, [showImage, targetRef])

  const datetimeDay = dayjs.utc(result.properties.datetime)

  return (
    <ResultBase
      disableNavigate={true}
      ref={targetRef}
      result={result}
      onClick="visitArticle"
      withBorder
      radius="sm"
      style={{
        height: '200px',
        borderColor:
          selectedPointId === result.documentId
            ? '#0091EA'
            : 'var(--mantine-color-gray-2)',
        ...(showImage ? {} : { aspectRatio: 1 }),
      }}
      data-cy="socialResult"
      {...props}
    >
      {showImage && (
        <div
          ref={imageRef}
          style={{
            position: 'relative',
            overflow: 'hidden',
            aspectRatio: `${isFullscreen ? 2.4 : 1.95}`,
            backgroundColor: 'var(--mantine-color-gray-2)',
          }}
        >
          <Image
            src={result.properties.src_image_url ?? result.thumbnail}
            h="100%"
            onError={() => setShowImage(false)}
          />
        </div>
      )}

      <Stack
        gap="xs"
        my="sm"
        mr="xs"
        pl="sm"
        pr="xs"
        mah={maxHeight}
        style={{
          overflowY: 'auto',
          overflowX: 'hidden',
          wordBreak: 'break-word',
          scrollbarWidth: 'thin',
          scrollbarColor: 'var(--mantine-color-gray-4) transparent',
        }}
      >
        <Text size="xs">
          {result.properties.source ?? '??'}
          {' • '}
          {datetimeDay &&
            datetimeDay
              .fromNow(true)
              .replaceAll(/^(\d*)\s(\w)\w+$/g, '$1$2')
              .replaceAll(/^a\s(\w)\w+$/g, '1$1')}
        </Text>
        {result.properties.summary && (
          <Text size="xs">{result.properties.summary.replaceAll('�', '')}</Text>
        )}
      </Stack>
    </ResultBase>
  )
}
