import { TerrapinLocationType } from '@/components/modals/SaveSearchModal/TerrapinLocationPanel/TerrapinLocation.ts'
import type { Point, Polygon } from 'geojson'
import { toPoint } from 'mgrs'

export const formatGeojson = (
  type: TerrapinLocationType,
  value: string | undefined,
) => {
  switch (type) {
    case TerrapinLocationType.latLong:
      return {
        type: 'Point',
        coordinates: value ? JSON.parse(value) : [0, 0],
      } as Point
    case TerrapinLocationType.polygon:
      return {
        type: 'Polygon',
        coordinates: value
          ? JSON.parse(value)
          : [
              [0, 0],
              [1, 0],
            ],
      } as Polygon
    case TerrapinLocationType.mgrs: {
      const point = value ? toPoint(value) : [0, 0]
      return {
        type: 'Point',
        coordinates: point,
      } as Point
    }
    default:
      return {
        type: 'Point',
        coordinates: [0, 0],
      } as Point
  }
}
