import { createRoot } from 'react-dom/client'
import { useAllResults } from '@/api/results'
import { emitter, EVENTS } from '@/events'
import { MapPointInfoTooltip } from '@/features/DantiMap/components/MapPointInfoTooltip'
import { useSetSelectedPointId } from '@/stores/map-store'
import type { ResultWithId } from '@/utils/types/result-types'
import mapboxgl from 'mapbox-gl'
import type { MutableRefObject } from 'react'

import './point-styles.css'

export const usePointInteractionListeners = (
  map: MutableRefObject<mapboxgl.Map | null>,
) => {
  const results = useAllResults()
  const setSelectedPointId = useSetSelectedPointId()
  const handleMouseover = (event: mapboxgl.MapLayerMouseEvent) => {
    event.target.getCanvas().style.cursor = 'pointer'
  }

  const handleClick = (event: mapboxgl.MapLayerMouseEvent) => {
    event.target.getCanvas().style.cursor = 'pointer'
    type ClickEvent = mapboxgl.MapLayerMouseEvent & { clickedOnLayer: boolean }
    const { clickedOnLayer } = event as ClickEvent
    if (event.features && !clickedOnLayer) {
      const id = event.features[0].properties?.['resultId'] as string | null
      const result = results.find((result: ResultWithId) => result.id === id)
      if (!result) {
        return
      }
      const coords = result?.geometry?.coordinates as [number, number]
      setSelectedPointId(result.id)
      const makeTooltip = () => {
        const div = document.createElement('div')
        const root = createRoot(div)
        root.render(MapPointInfoTooltip({ result }))
        return div
      }

      const popup = new mapboxgl.Popup({ closeButton: false })
        .setLngLat(coords)
        .setDOMContent(makeTooltip())

      if (map.current) {
        popup.addTo(map.current)
      }

      ;(event as ClickEvent).clickedOnLayer = true
      emitter.on(EVENTS.clearAll, () => {
        popup.remove()
      })
    }
  }

  const handleMouseout = (event: mapboxgl.MapLayerMouseEvent) => {
    event.target.getCanvas().style.cursor = 'default'
  }

  return {
    handleMouseover,
    handleClick,
    handleMouseout,
  }
}
