import { modals } from '@mantine/modals'
import type { SaveSearchSettingsModalParams } from '@/components/modals'
import { SAVED_SEARCH_MODES } from '@/utils/constants'

export function openSaveSearchModal({
  refId,
  params,
  providers,
  isNew,
}: SaveSearchSettingsModalParams): void {
  modals.openContextModal({
    modal: 'saveSearch',
    title: (
      <span style={{ fontSize: '32px', fontWeight: 700 }}>
        {params?.mode === SAVED_SEARCH_MODES.feed
          ? isNew
            ? 'Create an AOI saved search'
            : 'Update an AOI saved search'
          : 'Save this search'}
      </span>
    ),
    size: 'xl',
    centered: true,
    innerProps: { refId, params, providers },
  })
}
