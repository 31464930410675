import React from 'react'
import { Divider, ScrollArea, Stack, Text } from '@mantine/core'
import type { ShareRoleType } from '@/api/collections'
import { useAvatarDetails } from '@/stores/user-store'
import type {
  Collection,
  UnshareWithVendorParams,
} from '@/api/collections/types'
import { UserListItem } from './UserListItem'

export interface UserListProps {
  collection: Collection
  onRoleUpdate: (email: string, role: ShareRoleType) => Promise<void>
  onUnshare: (params: UnshareWithVendorParams) => Promise<void>
}

export function UserList({
  collection,
  onRoleUpdate,
  onUnshare,
}: UserListProps) {
  const { email } = useAvatarDetails()

  const [excludeEmails, setExcludeEmails] = React.useState<string[]>([])

  const handleUnshare = async (params: UnshareWithVendorParams) => {
    await onUnshare(params)
    setExcludeEmails([...excludeEmails, ...params.emails])
  }

  const currentUser = React.useMemo(
    () => collection.users.find((user) => user.email === email)!,
    [collection, email],
  )

  const users = React.useMemo(
    () =>
      collection.users
        .filter((user) => !excludeEmails.includes(user.email))
        .sort((a, b) => (a.email < b.email ? -1 : 1)),
    [collection, excludeEmails, email],
  )

  return (
    <Stack gap="sm">
      <Divider />
      <Text size="md" style={{ fontWeight: 'bold' }}>
        People with access
      </Text>
      <ScrollArea>
        {users.map((user) => (
          <UserListItem
            key={user.email}
            user={user}
            currentUser={currentUser}
            onUnshare={handleUnshare}
            onRoleUpdate={onRoleUpdate}
          />
        ))}
      </ScrollArea>
    </Stack>
  )
}
